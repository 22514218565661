import React, { Component } from "react";
import invWomn from '../../img/inv-wmn.jpg';


class InvisibleWomen extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        CS/INFO 4300: Language and Information teaches methodologies to make sense of the vast amounts of information available online, and how to relate it to the social context in which it appears. Applications include retrieving and analyzing unstructured textual information, information retrieval, sentiment analysis, and natural language processing.
      </p>
      <p className="description">
        The final project prompt is to build an app that integrates information retrieval, machine learning, and a social component. My team built <b>Invisible Women</b>, inspired by the momentum generated by the <i>Hidden Figures</i>  film of bringing stories of women in STEM to the forefront and giving them due recognition for their invaluable contributions to their fields. The live demo is still running - check it out!
      </p>
      <p className="description">
        <iframe className="centering" src="https://drive.google.com/file/d/1NH0niVmeGsQ5aCpasFWiOAF_txIqaILX/preview" width="640" height="480" title="presentation deck"></iframe>
      </p>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
          <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter class" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={invWomn} alt="Invisible Women" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Invisible Women</span><br/>
                <span className="subtitle-proj">Information Retrieval</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Invisible Women</h3>
                  <span className="price">Spring 2018</span>
                </div>
                {this.state.projtext}
              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://invisiblewomen.herokuapp.com/" target="_blank" rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>
                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/jericahuang/cs4300sp2018-InvisibleWomen" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default InvisibleWomen;

import React, { Component } from "react";
import projWorkay from '../../img/rio-me.jpg';

import flyer from '../../img/rio-flyer.jpg';
import aboutWorkay from '../../img/workay.png';

import sugarloaf from '../../img/rio-sugarloaf.jpg'
import present from '../../img/rio-present.jpg';
import salad from '../../img/rio-salad.jpg';
import me2 from '../../img/rio-me2.jpg';
import sm from "../../img/rio-sm.jpg";
import aboutBrazil from "../../img/rio-briefing.jpg";
import claudia from "../../img/rio-claudia.jpg";

class Workay extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        <img className="centering" src={flyer} width="400" draggable="false" alt="class description"/><br/>
        This Impact Trek began as a collaboration between the <a href="https://www.engineering.cornell.edu/students/undergraduate-students/special-programs/engineering-leadership-programs" target="_blank" rel="noopener noreferrer" >Cornell Engineering Leadership Program</a> and <a href="http://emzingo-u.com/" target="_blank" rel="noopener noreferrer">Emzingo</a>. Cornell
        students from all schools and majors were invited to apply. A team of 7 was assembled, with academic backgrounds in civil and environmental engineering, mechanical engineering, economics, international and rural development, and computer science. We met regularly throughout the second half of the fall 2017
        semester, engaging in teambuilding, design thinking, and project onboarding. Early in the semester, we learned about the client that we would be working with: <i><a href="https://workay.com.br/" target="_blank" rel="noopener noreferrer">Workay!</a></i>
      </p>
      <img className="centering" src={aboutWorkay} style={{maxWidth:"80%"}} draggable="false" alt="about Workay"/>
      <p className="description">
        Workay! is an early stage social impact startup in Rio de Janeiro that connects skilled professionals with commercial and residential customers for specialized repairs and reforms services. Their mission goes beyond connecting skilled professionals with clients; they seek to change cultures. By providing training to women and individuals living in socially vulnerable areas of the city, Workday! empowers them to build experience, gain credibility, and interact with clients of all kinds. This will equip them to contribute to the repair and reforms industry as professionals and share their success with their communities.
      </p>
      <p className="description">
        The entirety of the fall 2017 semester was dedicated to preparing for our January 2018 trip to Rio de Janeiro. In an introductory Skype call with Workay!’s founders, Julianna and Patrick, they presented to us the question:<br/>
        <span className="centering"><b>“How might we scale our organization while ensuring high quality services?”</b></span><br/>
        With this prompt in mind, we conducted research about various aspects of life in Brazil and had lessons in improving our cross-cultural fluency. Though we shared a love for problem solving and had the design thinking tools inside of us, we were encouraged to not jump to solutions right away, for none of us would anticipate the impact that the local immersion would have on us.
      </p>
      <img src={aboutBrazil} className="centering" alt="Brazil information" style={{maxWidth:"80%"}} draggable="false"/>
      <p className="description">
        When I first arrived in Rio de Janeiro, not knowing Portuguese, I felt as if I had woken up in a new world. I quickly realized that there was so much that I did not know, and only in my humility was I able to attune my mind to adapt to the new environment and culture. We had a full schedule for the week, with cultural immersions all over the city, stakeholder interviews, and dedicated project work (supported by English-Portuguese interpreters). Everything was intentionally planned, and it was essential to be present and keep an open mind. Despite the heavy media coverage about Rio from the 2016 Summer Olympics and 2014 FIFA World Cup, I learned about a side of Rio that would've never been possible if I came as a tourist.  (expanded upon in <a href="https://www.youtube.com/watch?v=1W_zM7koJy8" target="_blank" rel="noopener noreferrer">this video</a> by Vox)
      </p>
      <p className="description">
        Two pivotal experiences that shaped our business proposal to Workay were:<br/>
      </p>
      <ol>
        <li><b>Interviewing Claudia</b><br/>
          Claudia is an electrician who works as a countractor through Workay!. She shared with us stories of growing up in her village, what difference Workay! has made in her life, and challenges of interactions with some of her clients that stem from racial/social/class disparities. To her, it might have just been recounting her day-to-day life, but to us, it illuminated the blindspots we had in fully understanding what specific problems we should be solving for.
          <br/>
          <img src={claudia} alt="Claudia featured on a Workay flyer" className="centering" draggable="false" />
          <br/>
        </li>
        <li><b>Visiting Santa Marta favela</b><br/>
          Favelas are complex, vibrant places. Commonly built on hillside terrain, favelas began in response to immigration and shortage of affordable housing amidst urbanization. Today, favela residents comprise about 23% of Rio’s population. Many factors including disproportionate access to resources, racial segregation, limitations of the terrain, and stigmatization contribute to the disparity between favela communities and those “of the land.” When we visited Santa Marta favela, we were fortunate to have Fumaça, a resident of the favela, lead us on a tour and even invite us into his home. Along the way, we played soccer matches with local children and learned about the community’s entrepreneurial spirit with initiatives like <a href="https://believe.earth/en/saladorama-democratizing-healthy-food-in-brazil/" target="_blank" rel="noopener noreferrer">Saladorama</a> and <a href="https://www.theguardian.com/sustainable-business/providing-electricity-rio-de-janeiro-favelas" target="_blank" rel="noopener noreferrer">Light</a> which not only serve the community, but foster trust through close relations and collaborative solution building with the residents.
        </li>
      </ol>
      <p className="description centering">
      <iframe title="Workay! Final Deliverable Presentation" src="https://docs.google.com/presentation/d/e/2PACX-1vQIK7QfzIHPT1E4V-HoqyFgOS1StPKWSpUdoG-ECT9iGLispzCx3UvkwCxgsAdPuG3uH3fnAz0abf4S/embed?start=false&loop=false&delayms=60000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      </p>
      <p className="description">
        Ultimately, it was listening, empathizing, and connecting the dots that derived the insights for creating a successful business process proposal for Workay!, not some stroke of genius or existing idea adaptation (<i>“Uber for repairs/reforms services”</i>). After we pitched our final deliverable, I couldn't believe that just a few months ago, we had first read the briefing on Workay! and had a drastically different design process and deliverable idea.
      </p>
      <p className="description">
        This trek ingrained in me the power of understanding. To design a product or process to be integrated into a community, we <b>must</b> include the experiences of stakeholders who will be impacted, preferably through firsthand observation or listening. It makes for a creative process rich grounded in the truth of their lived experiences, even allowing the opportunity to form friendships across hemispheres. Obrigada! <br/>
        Upon returning to the spring 2018 semester at Cornell, two teammates and I presented our project in the <a href="https://oei.cornell.edu/program/showcase/" target="_blank" rel="noopener noreferrer">Engaged Leadership at Cornell</a> project showcase. Our poster can be found <a href="../docs/Brazil-poster.pdf" target="_blank" rel="noopener noreferrer">here</a>.
      </p>
      <p className="description">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/-1J3050ILcg" frameBorder="0" className="centering" title="Rio montage"></iframe>
      </p>

          <div className="carousel-wrapper">
            <div className="carousel">
              <a className="carousel-item" href="#one!"><img src={sugarloaf} width="300" height="200" draggable="false" alt="sugarloaf mountain"/></a>
              <a className="carousel-item" href="#two!"><img src={present} width="300" height="200" draggable="false" alt="group presentation"/></a>
              <a className="carousel-item" href="#three!"><img src={salad} width="300" height="200" draggable="false" alt="salad startup"/></a>
              <a className="carousel-item" href="#four!"><img src={me2} width="300" height="200" draggable="false" alt="group design session"/></a>
              <a className="carousel-item" href="#five!"><img src={sm} width="300" height="200" draggable="false" alt="overlooking Rio"/></a>
            </div>
          </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
		            <div className="gallery-curve-wrapper">
                  <div className="gallery-cover gray">
		                <img className="responsive-img" src={projWorkay} alt="Rio" draggable="false"/>
                  </div>
		              <div className="gallery-header">
		              <span>Social Entrepreneurship Consulting<br/><span className="subtitle-proj"><i className="material-icons" style={{fontSize: "14px"}}>location_on</i> Rio de Janeiro, Brazil</span></span>

		                </div>
		              <div className="gallery-body">
		                <div className="title-wrapper">
		                    <h3>Impact Trek</h3>
                 			<span className="price">January 2018</span>
		                </div>
		                {this.state.projtext}
		              </div>
		              <div className="gallery-action">
		                <a className="btn-floating btn-large waves-effect waves-light" href="../docs/Brazil-poster.pdf" target="_blank" rel="noopener noreferrer"><i className="material-icons">image</i></a>
		              </div>
		            </div>
		          </div>
      );
  }
}
 export default Workay;

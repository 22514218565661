import React, { Component } from "react";
import FadeIn from 'react-fade-in';
import '../css/gallery-materialize.min.css';
import '../css/gallery-more.css';
import '../css/portfolio.css';
import '../css/about.css';
import 'materialize-css';
import Navbar from './Navbar';

import code from '../img/me-codeshot.jpg';
import enp from '../img/me-enp.jpg';
import peak from '../img/me-peak.jpg';
import baby from '../img/me-baby.jpg';
import speech from '../img/me-kessler-about.jpg';
import oly from '../img/me-oly.jpg';
import ghibli from '../img/ghibli.jpg';
import film from '../img/proj-jubilee.jpg';
import pres from '../img/me-pres.jpg';
import skate from '../img/me-sk8.jpg';
import piano from '../img/me-piano.jpg';
import puget from '../img/me-puget.jpg';
import cornellSq from '../img/me-cornell-sq.jpg';
import cornellWide from '../img/me-cornell-wide.jpg';
import ada from '../img/ada.jpeg';

declare var $ : any;

class About extends Component {

	componentDidMount(){
		$('.carousel:not(.carousel-slider)').carousel({
			dist: 0,
			padding: 10
		});
		$('.carousel.carousel-slider').carousel({
			fullWidth: true,
			indicators: true,
			onCycleTo: function(el) {
				$('.nav-background img').removeClass('active');
				$('.nav-background img').eq($(el).index()).addClass('active');
			}
		});

  	}

    render() {
    	return(
       <div>
		<Navbar/>
          <nav className="nav-extended">
	      	<div className="nav-background">
	        	<div className="pattern active" id="headerSeattle">
	        	</div>
	      	</div>
	      <div className="nav-wrapper container">
	        <div className="nav-header center">
	          <div id="name-container">
	            <div id="name-first">
	              <span className="name">Jerica</span><br />
	              <span className="name-exp">/jˈɛrɪkə/</span>
	            </div>
	            <div id="name-last">
	              <span className="name">Huang</span><br />
	              <span className="name-exp">黄</span>
	            </div>
	            	<div id="pronouns">she&nbsp;&nbsp;/&nbsp;&nbsp;her&nbsp;&nbsp;/&nbsp;&nbsp;她</div>
		        </div>
		       </div>
		      </div>
		    </nav>
		    <div id="main" className="section gray">
		      <div className="container">
		          <div className="about-text-body">
		            <h2>I'm a Seattle-based creative.</h2>
		            <p>
		            	The core of my work is the belief in creating products and experiences derived from empathy.
		            	By seeking to listen and uplift untold stories around us, we can bridge the perceived barriers to potential, and in the process, expand our worldview. When employed with empathy, technology and storytelling can become great equalizing forces that shape a more beautiful and just world. I’m carving my own path at their intersection.
					</p>
					<p>
						Throughout my life, I've learned that <i>things aren't always as they seem</i> and <i>things don't have to be the way they are</i>. I have an insatiable curiosity to delve deep to uncover hidden truths, and boldness to pave a future where they are seen in new light.
						In everything I do, through many mediums—software engineering, filmmaking, teaching, organizational leadership, piano performance, product design, figure skating, public service, adventures into the unknown, and just being the best me I can be—I seek to share stories that reimagine what could be, cast a vision of a brighter future, and invite others in.
		            </p>
				  </div>
								<FadeIn transitionDuration="1000">
								<div className="carousel-wrapper" id="about-carousel-wrapper">
									<div className="carousel">
										<a className="carousel-item" href="#one!"><img src={code} alt="coding" width="300" height="200" /></a>
										<a className="carousel-item" href="#two!"><img src={enp} alt="elephant nature park" width="300" height="200" /></a>
										<a className="carousel-item" href="#three!"><img src={peak} alt="hiking summit" width="300" height="200" /></a>
										<a className="carousel-item" href="#four!"><img src={speech} alt="speech" width="300" height="200" /></a>
										<a className="carousel-item" href="#five!"><img src={puget} alt="Miss Puget Sound" width="300" height="200" /></a>
										<a className="carousel-item" href="#six!"><img src={skate} alt="synchronized skating" width="300" height="200" /></a>
										<a className="carousel-item" href="#seven!"><img src={ghibli} alt="ghibli" width="300" height="200" /></a>
										<a className="carousel-item" href="#eight!"><img src={oly} alt="photography" width="300" height="200" /></a>
										<a className="carousel-item" href="#nine!"><img src={pres} alt="presentation" width="300" height="200" /></a>
										<a className="carousel-item" href="#ten!"><img src={film} alt="filmmaking" width="300" height="200" /></a>
										<a className="carousel-item" href="#eleven!"><img src={baby} alt="artist" width="300" height="200" /></a>
										<a className="carousel-item" href="#twelve!"><img src={piano} alt="pianist" width="300" height="200" /></a>
									</div>
								</div>
								</FadeIn>
								<div className="about-text-body">
		            <p>
		              I most recently worked full-time as a Software Development Instructor and Curriculum Product Manager at <a href="https://adadevelopersacademy.org/" target="_blank" rel="noopener noreferrer">Ada Developers Academy</a>, where I taught skills and inspired cultures that empower the up-and-coming generation of technology professionals that will push the software industry forward with innovation and inclusivity.
		              I was formerly a technical product manager at Microsoft and have a software engineering background at large corporations and startups.
		            </p>
						<img src={ada} className="centering" id="ada" alt="teaching at Ada"/>
					<p>
		              Teaching and mentoring have been extremely formative and fulfilling throughout my journey. Outside of work, I have been a mentor for 2+ years in front-end development and data structures + algorithms to software engineers upleveling their careers through <a href="https://formation.dev/" target="_blank" rel="noopener noreferrer">Formation</a>. As someone fascinated by the technical and ethical implications of Artificial Intelligence and Machine Learning, I teach both virtual and in-person Artificial Intelligence courses to high school students with <a href="https://www.inspiritai.com/" target="_blank" rel="noopener noreferrer">Inspirit AI</a>, as well as developing technical refinements to their programs and experience. I have formerly held technical teaching positions to a variety of audiences through Girls Who Code, Codecademy, Cornell University, Downtown Ithaca, and the University of Pennsylvania. I also enjoy coaching figure skating to all ages and abilities.
					</p>
					<p>
		              For fun, I love to go on spontaneous adventures with my friends, my dog, and my camera, hike during all four seasons, work on creative side projects, travel to new destinations, have deep conversations about big ideas, practice a variety of figure skating disciplines, and explore nuances that make my community home.
		            </p>
					<div className="row" id="school-blurb">
						<div className="col l8 push-l4 m12 s12">
						<p>
			              I graduated with a B.S. in Computer and Information Science from Cornell University's School of Engineering, where I was a 2017 <a href="https://www.engineering.cornell.edu/students/undergraduate-students/entrepreneurial-options-undergrad-students/kessler-fellows-program" target="_blank" rel="noopener noreferrer">Kessler Fellow</a>, <a href="https://scl.cornell.edu/get-involved/cornell-commitment/meinig-family-cornell-national-scholars" target="_blank" rel="noopener noreferrer">Meinig Family Cornell National Scholar</a> and <a href="https://www.engineering.cornell.edu/students/undergraduate-students/special-programs/engineering-leadership-programs" target="_blank" rel="noopener noreferrer">Engineering Leadership Certificate</a> recipient. 
						</p>
						<p>But as a life enthusiast, my education never ends. I spent my Summer 2023 directing and producing at <a href="https://cinema.usc.edu/summer/classes.cfm?s=d&id=31" target="_blank" rel="noopener noreferrer">USC School of Cinematic Arts x Warner Brothers Studio</a> and completed Harvard Business School's <a href="https://online.hbs.edu/verify-certificate?dvid=473GZPDH" target="_blank" rel="noopener noreferrer">Credential of Readiness</a>. I'm currently deepening my study on Artificial Intelligence and Machine Learning through the <a href="https://online.stanford.edu/programs/artificial-intelligence-professional-program" target="_blank" rel="noopener noreferrer">Stanford AI Professional Program.</a></p>
						<p>In 2021, I completed <a href="https://www.leadershiptomorrowseattle.org/" target="_blank" rel="noopener noreferrer">Leadership Tomorrow Seattle</a>'s flagship program, Redemptive Business/Media &amp; Entertainment tracks at <a href="https://www.praxislabs.org/praxis-academy" target="_blank" rel="noopener noreferrer">Praxis Academy</a>, <a href="https://cinema.usc.edu/summer/index.cfm" target="_blank" rel="noopener noreferrer">USC School of Cinematic Arts</a> Summer Filmmaking course, and the RG Pro Production Intensive at <a href="https://nwfilmforum.org/" target="_blank" rel="noopener noreferrer">Northwest Film Forum</a>.
						</p>
									</div>
									<div className="col l4 pull-l8 m12 s12 centering">
									<p><img src={cornellSq} draggable="false" alt="cornell graduation" id="cornell-sq"/></p>
									<img src={cornellWide} draggable="false" alt="cornell graduation" id="cornell-wide"/>
									</div>
					</div>
					<p>
							My work has been graciously supported by the <a href="https://students.googleblog.com/2016/06/presenting-our-2016-google-scholarship.html" target="_blank" rel="noopener noreferrer">Google Anita Borg Memorial Scholarship</a>, <a href="http://www.microsoft.com/scholarship" target="_blank" rel="noopener noreferrer">Microsoft Scholarship</a>, <a href="https://scl.cornell.edu/get-involved/cornell-commitment/meinig-family-cornell-national-scholars" target="_blank" rel="noopener noreferrer">Meinig Family Cornell National Scholarship</a>, <a href="https://www.engineering.cornell.edu/engdiversity" target="_blank" rel="noopener noreferrer">Cornell Diversity Programs in Engineering</a>, the <a href="https://www.missamerica.org/" target="_blank" rel="noopener noreferrer">Miss America</a> state and local organizations of New York and Washington, and countless people on my journey.<br/><span className="centering">Thank you.</span>
			            </p>
		        </div>
		      </div>
		    </div>
	       </div>
	       )
    }

}

export default About;

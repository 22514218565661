import React, { Component } from "react";
import projMS from '../../img/proj-msft.jpg';

import mePresent from '../../img/ms-me.jpeg';

import rainier from '../../img/ms-rainier.jpg'
import seattle from '../../img/ms-seattle.jpg';
import msLogo from '../../img/ms-me.JPG';
import visStudio from '../../img/ms-vstudio.png';
import desk from "../../img/ms-desk.jpg";

class Microsoft extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        As an Explorer Intern in the Static Analysis Strategy Team within the Cloud &amp; Enterprise organization,
        I rotated through the Program Manager and Software Engineer roles with a team of 2
        other Explorer Interns to design and execute an end-to-end project for SARIF
        (<b>S</b>tatic <b>A</b>nalysis <b>R</b>esult <b>I</b>nterchange <b>F</b>ormat), Microsoft's new standard Static Analysis format.
      </p>
      <p className="description">
        From the first day, Microsoft made clear to me the prevalence of its mission to "empower every person and organization on the planet
        to achieve more," from the ambience of team settings, the details within my project specification, its printing
        on the back of every employee and intern badge, and company-wide initiatives that encourage exploration, diversity, and wellness. With
        the recent changes Microsoft has undergone, I also realized the importance of having a leader who embodies the mission and values of
        an organization.
      </p>
        <p className="description">
          In my PM rotation, we researched SARIF's strategic advantage in the static analysis space and determined user
          needs through conducting interviews, running brainstorming sessions, and synthesizing findings into user stories. With newfound
          learnings, we designed the specification for our engineering project: to create and refine viewing experiences for SARIF in various
          forms for broad reach and impact: in the forms of VSIX (Visual Studio extension), the VS Code editor, an internal standalone viewer.
          Technologies used include C#, Windows Presentation Foundation, JavaScript, and Git. Check out my contributions in the open-source
          SARIF SDK repository on Github! (linked above in the code brackets button). At the conclusion of the internship, I demoed my
          specific contributions and was given the responsibility of telling the <em>story</em> of what my Explorer pod accomplished that summer.
        </p>
        <img src={mePresent} width="60%" className="centering" alt="me giving a presention" />
        <p className="description">
          I loved being an explorer. As an engineering student with a wide range of interests and growing experiences in engineering leadership
          and digital product design, I found immense value in experiencing both program management and software engineering roles: how
          they complement, build upon, and relate to one another. An epiphany for me was the realization that I cared a lot less about coding than
          I had thought; I care about the people I'd be impacting, the values and mission we engineers can imbue into software, and the joys of solving
          micro- and macro-sized problems through engineering collaborations.
        </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={rainier} width="300" height="200" draggable="false" alt="Mt Rainier"/></a>
          <a className="carousel-item" href="#two!"><img src={seattle} width="300" height="200" draggable="false" alt="Seattle skyline"/></a>
          <a className="carousel-item" href="#three!"><img src={msLogo} width="300" height="200" draggable="false" alt="Microsoft logo"/></a>
          <a className="carousel-item" href="#four!"><img src={visStudio} width="300" height="200" draggable="false" alt="Visual Studio"/></a>
          <a className="carousel-item" href="#five!"><img src={desk} width="300" height="200" draggable="false" alt="work desk"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter industry" onClick={this.handleClick}>
		            <div className="gallery-curve-wrapper">
                  <div className="gallery-cover gray">
		                <img className="responsive-img" src={projMS} alt="Microsoft" draggable="false"/>
                  </div>
		              <div className="gallery-header">
                       <span>Microsoft Explorer Internship</span>
                       <br/>
                       <span className="subtitle-proj">Software Engineering + Program Management</span>
		              </div>
		              <div className="gallery-body">
		                <div className="title-wrapper">
		                    <h3>Microsoft</h3>
                       <span className="price">Summer 2016</span>
		                </div>
		                {this.state.projtext}
		              </div>
		              <div className="gallery-action">
		                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/microsoft/sarif-sdk/commits?author=jericahuang" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>
                </div>
		            </div>
		          </div>
      );
  }
}
 export default Microsoft;

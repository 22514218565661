import React, { Component } from "react";

import projGoog from '../../img/proj-goog.jpg';

import swigJava from '../../img/google-slide1.jpg';
import kesslerPres from '../../img/me-kessler.jpg';

import desk from '../../img/goog-desk.jpg'
import nougat from '../../img/goog-nougat.jpg';
import bikes from '../../img/goog-bikes.jpg';
import cafe from '../../img/goog-cafe.jpg';
import leanin from "../../img/goog-leanin.jpg";


class Google extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
        <p className="description">
          As a software engineering intern on the Mobile Ads Infrustracture team at Google's Mountain View headquarters, I worked on
          up-and-coming tools that reduce friction in developers' workflow, specifically, in mobile user interface (UI) validation.
          My work played a critical role in integrating a contextual mobile UI differ tool called SmartDiff into the Google-wide image capture
          and comparison framework, which required a hermetic, native call for Android and iOS. I worked on the Android side.
        </p>

        <p className="description">
          The goal of my intern project was to enable cross-platform functionalities for SmartDiff (a native C++ library) in the Android runtime environment.
          After delving into the codebase and conducting research, I learned that the approach that best leverages the existing library is to create a cross-language, cross-platform interface
          for the library using the <a href="http://swig.org/" target="_blank" rel="noopener noreferrer" >Simplified Wrapper and Interface Generator</a> (SWIG)
          or the <a href="https://docs.oracle.com/javase/8/docs/technotes/guides/jni/" target="_blank" rel="noopener noreferrer" >Java Native Interface</a> (JNI), rather than to
          re-implement SmartDiff in Java. Another component of my project is developing a novel data visualizion that interprets
          SmartDiff results. My project culminated in a pitch to the Google-wide image capture and comparison framework team.
        </p>
      <img src={swigJava} width="100%" draggable="false" alt="Swig in Java"/>
        <p className="description">
          I learned <i>so much</i>. I learned the importance of feasibility testing. I learned about C++ and Android development, Google's build
          infrastructure, the Java Virtual Machine, and inter-language operancy: technical concepts I had no background knowledge in at the beginning of
          my internship. I learned to ask the seemingly dumb questions early and often because new, fresh perspectives can shed light on things no one has
          ever considered. I learned to start small and build up, test often, and to not worry about getting everything perfect on the first try. I
          learned to look for opportunity in situations that look like failure. I learned how my work fits into Google's mission to organize the world's
          information and make it universally accessible and useful.
        </p>
  <img src={kesslerPres} width="100%" draggable="false" className="centering" alt="Presentation"/>
        <p className="description">
          In the backdrop of all this, being a 2017&nbsp;
          <a href="https://www.engineering.cornell.edu/students/undergraduate-students/entrepreneurial-options-undergrad-students/kessler-fellows-program" target="_blank" rel="noopener noreferrer" >Kessler Fellow</a>
          &nbsp;inspired me to keep an open mind. Initially, I underestimated the impact of my product area (Tools and Infrastructure)
          because my work wouldn't be deployed to the global consumer base, only to internal developers. Arriving and working at Google gave me a brand new perspective.
          During the first week of onboarding, I was in utter awe of the scale and usability of Google's internal development framework, and realized that engineers
          working my product area are responsible for developing it: for innovating based on their own software development insights, for noticing potentials for improvement in development processes and then making it happen, for shaping corporate cultures,
          for making someone's development and deployment experience so much easier. In Tools and Infrastructure, I learned that we engineers can serve as our own product managers
          and wear many different hats. This sentiment built
          upon my internship at Microsoft the summer prior, where I worked in a similar, Microsoft-equivalent product area (Developer Division).
          Upon returning to Cornell after this internship, I was invited to give an hour-long presentation about my summer for the Kessler Fellows Symposium series. I
          conveyed to listeners that things aren't always as they seem and how to apply effectual thinking, which I related not only to my summer, but
          also to the journey I took to get here.
        </p>

        <p className="description">
          And of course, I had fun! I was able to pursue my long-term passion for figure skating and train daily before I head into the office. With the other Bay Area
          Kessler Fellows, I explored the Silicon Valley startup and engineering scene, and was fortunate to meet and share stories with Andy Kessler. I participated in
          Lean-INterns with fellow female Google engineering interns who became a group I could count for motivation and solace. I marched with
          Google in the San Francisco Pride Parade. I starred as an extra in a <a href="https://vimeo.com/222307881" target="_blank" rel="noopener noreferrer" >film</a> by Maku
          Creative (can you spot me?). I fell in love with the natural world more than ever during my excursion in Yosemite National Park. I took way too
          many Google Grow classes, the most memorable ones being Search Inside Yourself, cooking at KitchenSync cafe, and 3-D printing. I volunteered with my team for
          GoogleServe. I witnessed the internal dynamics of how Google responded to a challenging situation. I learned that the value of
          diversity stems from an appreciation of individuality and has an indisputable place in the tech industry. And I met so many inspiring, generous, influential
          people who helped inform my future and to whom I can never fully convey the depths of my gratitude.
        </p>

          <div className="carousel-wrapper">
            <div className="carousel">
              <a className="carousel-item" href="#one!"><img src={desk} width="300" height="200" draggable="false" alt="Internship desk"/></a>
              <a className="carousel-item" href="#two!"><img src={nougat} width="300" height="200" draggable="false" alt="HQ"/></a>
              <a className="carousel-item" href="#three!"><img src={bikes} width="300" height="200" draggable="false" alt="bikes"/></a>
              <a className="carousel-item" href="#four!"><img src={cafe} width="300" height="200" draggable="false" alt="Cafe"/></a>
              <a className="carousel-item" href="#five!"><img src={leanin} width="300" height="200" draggable="false" alt="LeanIn"/></a>
            </div>
          </div>
      </div>,
      clicked: true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter industry" onClick={this.handleClick}>
		            <div className="gallery-curve-wrapper">
                  <div className="gallery-cover gray">
		                <img className="responsive-img" src={projGoog} alt="Google" draggable="false"/>
                  </div>
		              <div className="gallery-header">
		                <span><span style={{fontFamily: 'Montserrat, sans-serif', fontSize:'15px'}}><span style={{color:'#4285f4'}}>G</span><span style={{color:'#ea4335'}}>o</span><span style={{color:"#fbb305"}}>o</span><span style={{color:"#4285f4"}}>g</span><span style={{color:"#34a853"}}>l</span><span style={{color:"#ea4335"}}>e</span>
                    </span><br/>Software Engineering Internship</span>
		              </div>
                    <div className="gallery-body">
                    <div className="title-wrapper">
                        <h3>Google</h3>
                        <span className="price">Summer 2017</span>
                    </div>
                    {this.state.projtext}
                    </div>
		            </div>
		          </div>
      );
  }
}
 export default Google;

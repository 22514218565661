import React, { Component } from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import PixarPortfolio from './components/PixarPortfolio';
import Film from './components/Film';
import Error from './components/Error';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
        <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/film" component={Film} />
            <Route path="/PixarPortfolio" component={PixarPortfolio} />
            <Route path='*' component={Error}/>
          </Switch>
        </BrowserRouter>
        <Footer />
      </div>
    );
  }
}

export default App;

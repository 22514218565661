import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import 'materialize-css';

declare var $ : any;

class Navbar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {};

  getNavLinkClass = path => {
    return this.props.location.pathname === path
      ? "nav-item active"
      : "nav-item";
  };

  render() {
    return (
      <div>
      <nav className="navbar dark">
        <div className="nav-wrapper">
          <a href="/" className="brand-logo"><i className="icon-home"></i></a>
          <ul id="nav-mobile" className="right hide-on-med-and-down">
            <li className={this.getNavLinkClass("/about")}>
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className={this.getNavLinkClass("/portfolio")}>
              <Link className="nav-link" to="/portfolio">
                Tech
              </Link>
            </li>
            <li className={this.getNavLinkClass("/film")}>
              <Link className="nav-link" to="/film">
                Film
              </Link>
            </li>
           <li id="link-icons">
              <a className="tooltipped" data-position="top" data-delay="50" data-tooltip="jh2263 [at] cornell [dot] edu"><i className="fa fa-1x fa-envelope" aria-hidden="true"></i></a>
              <a href="http://www.github.com/jericahuang" target="_blank" rel="noopener noreferrer"><i className="fab fa-1x fa-github-alt" aria-hidden="true"></i></a>
              <a href="http://www.goodreads.com/jericahuang" target="_blank" rel="noopener noreferrer"><i className="fa fa-1x fa-book" aria-hidden="true"></i></a>
              <a href="http://www.linkedin.com/in/jericahuang" target="_blank" rel="noopener noreferrer"><i className="fab fa-1x fa-linkedin" aria-hidden="true"></i></a>
              <a href="http://www.instagram.com/jericahuang" target="_blank" rel="noopener noreferrer"><i className="fab fa-1x fa-instagram" aria-hidden="true"></i></a>
            </li>
          </ul>
          <a href="#" data-target="slide-out" className="sidenav-trigger button-collapse right"><i className="material-icons black-text">menu</i></a>
        </div>
      </nav>
      <ul id="slide-out" className="sidenav">
        <li className={this.getNavLinkClass("/")}>
          <Link className="waves-effect waves-light sidenav-close" to="/">
                Home
          </Link>
        </li>
        <li className={this.getNavLinkClass("/about")}>
          <Link className="waves-effect waves-light sidenav-close" to="/about">
                About
          </Link>
        </li>
        <li className={this.getNavLinkClass("/portfolio")}>
          <Link className="waves-effect waves-light sidenav-close" to="/portfolio">
                Tech
          </Link>
        </li>

        <li className={this.getNavLinkClass("/film")}>
          <Link className="waves-effect waves-light sidenav-close" to="/film">
                Film
          </Link>
        </li>
        <div id="sidenav-icons">
          <a className="tooltipped" data-position="top" data-delay="50" data-tooltip="jh2263 [at] cornell [dot] edu"><i className="fa fa-2x fa-envelope" aria-hidden="true"></i></a>
          <a href="http://www.github.com/jericahuang" target="_blank" rel="noopener noreferrer" ><i className="fab fa-2x fa-github-alt" aria-hidden="true"></i></a>
          <a href="http://www.goodreads.com/jericahuang" target="_blank" rel="noopener noreferrer" ><i className="fa fa-2x fa-book" aria-hidden="true"></i></a>
          <a href="http://www.linkedin.com/in/jericahuang" target="_blank" rel="noopener noreferrer" ><i className="fab fa-2x fa-linkedin" aria-hidden="true"></i></a>
          <a href="http://www.instagram.com/jericahuang" target="_blank" rel="noopener noreferrer" ><i className="fab fa-2x fa-instagram" aria-hidden="true"></i></a>
        </div>
      </ul>
      </div>
    );
  }
}

export default withRouter(Navbar);

import React, { Component } from "react";
import acs from '../../img/proj-acs.jpg';


class ACSStory extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        The American Cancer Society’s mission is to save lives, celebrate lives, and lead the fight for a world without cancer. When cancer strikes, its impact is wide-ranging. The American Cancer Society leads a multifaceted approach by launching breakthrough research, sharing resources on outsmarting cancer, enabling local communities to support those affected, and convening powerful activists to create awareness and impact. Enlisting Microsoft enterprise technology is expanding new frontiers of impact through technical advancement and creative application.
      </p>
      <p className="description">
        <div className="row">
          <div className="col m6 s12">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/hUNKUwYH0Tg" frameBorder="0" className="centering" title="Microsoft Stories of Hope" allowFullScreen></iframe>
          </div>
          <div className="col m6 s12">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/05Tsk4YZfrc" frameBorder="0" className="centering" title="Microsoft Stories of Hope" allowFullScreen></iframe>
          </div>
        </div>
        </p>
      
      </div>,
      clicked: true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={acs} alt="American Cancer Society storytelling portal" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>American Cancer Society: Stories of Hope <span role="img" aria-label="silver medal">🥈</span></span>
                <br/>
                <span className="subtitle-proj">Microsoft //oneweek Hackathon</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Stories of Hope</h3>
                  <span className="price">Summer 2019</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://www.cancer.org/latest-news/stories-of-hope.html" target="_blank"  rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default ACSStory;

import React, { Component } from "react";
import entropy from '../../img/proj-entropy.jpg';

import gp from '../../img/en-gp.jpg';
import cota from '../../img/en-cota.jpg';
import haz from '../../img/en-haz.jpg';
import cota2 from '../../img/en-cota2.jpg';
import team from "../../img/en-team.jpg";

class Entropy extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Entropy is a 2-D puzzle platformer game developed in Java using the libGDX game development framework and Box2D physics engine, as a semester-long project for CS 3152: Introduction to Computer Game Architecture. Working with a team of 6,
        we were given the go-ahead to create any game we wanted to create, with the only requirements being that the timeline must be feasible
        within the semester and that it features innovative gameplay.
      </p>
      <p className="description">
        Working through an iterative development methodology, the class progressed like a real game development studio. We worked through ideation, non-digital prototype, alpha release, beta release, and final release milestones, each time incorporating playtesting feedback for the next prototype. I was fascinated with how game development brings together so many technical and artistic mediums to captivate an audience.
        <br/><br/>
        <b>Technical</b>: object-oriented programming, physics engines, memory optimization, AI sensing and thinking, algorithmic efficiency<br/>
        <b>Artistic</b>: story design, dialogue, character development, graphic design, sound and music composition
      </p>
      <p className="description">
        Entropy was released at the 2016 GDIAC (Game Design Initiative at Cornell) Showcase. It was lauded as having "a very distinctive style with some of the best art assets in the class."
        Click the code brackets button for instructions on how to play it!
      </p>

      <p className="description">
        <b>High Concept Statement</b><br/>
        After a nuclear apocalypse, plants and animals flourish where humans once lived. Cota, a mysterious creature that can harness the power of
        plants, is born into this strange environment, unsure of its place in the world. As Cota, use your power to grow and shrink vines to traverse
        the harsh environment. Avoid contact with the poisonous creatures that dominate both sky and land while you explore to find a place that you
        may call home in this hostile land.
      </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={gp} width="300" height="200" draggable="false" alt="gameplay"/></a>
          <a className="carousel-item" href="#two!"><img src={cota} width="300" height="200" draggable="false" alt="main character"/></a>
          <a className="carousel-item" href="#three!"><img src={haz} width="300" height="200" draggable="false" alt="hazard"/></a>
          <a className="carousel-item" href="#four!"><img src={cota2} width="300" height="200" draggable="false" alt="main character"/></a>
          <a className="carousel-item" href="#five!"><img src={team} width="300" height="200" draggable="false" alt="group assessment"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
           <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter class" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={entropy} alt="Entropy" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Entropy</span>
                <br/>
                <span className="subtitle-proj">Computer Game Architecture and Design</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Entropy</h3>
                  <span className="price">Spring 2016</span>
                </div>
                {this.state.projtext}
              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/jericahuang/entropy" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>
                <a className="btn-floating btn-large waves-effect waves-light" href="../docs/entropyGameManual.pdf" target="_blank" rel="noopener noreferrer"><i className="material-icons">description</i></a>
              </div>
            </div>
          </div>
      );
  }
}
 export default Entropy;

import React, { Component } from "react";
import lt from '../../img/proj-lt.jpg';

class LTSea extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Leadership Tomorrow Seattle brings together a cohort of 80 leaders across the public, private, and non-profit sectors. During the 10-month Flagship Program, participants learn about the elements of a healthy community and how they are interconnected. We look at issues from a systemic point of view, consider diverse perspectives, and practice collaborating across differences. There is specific emphasis on how systems of inequity, particularly racial inequity, must be addressed to achieve a healthy, resilient community. Graduates of LT Seattle have a deeper understanding of the issues that shape the Puget Sound region, are inspired to get engaged in their community, and have the leadership skills necessary to get things done.
      </p>

      <p className="description">
        I had my sights set on applying to LT Seattle at the start of 2020. Little did I know how the year would unfold. When I received my acceptance notice in June, the world had been through so much. Numerous events amplified amidst the COVID-19 pandemic revealed with painful clarity the long-term devastating effects of structural and systemic racism. The work to break these systems of oppression through building knowledge, skills, relationships, and courage to act together has never been more timely and urgent.
      </p>

      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={lt} alt="LT Seattle class of 2021" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Leadership Tomorrow Seattle '21</span>
                <br/>
                <span className="subtitle-proj">Civic Leadership</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Leadership Tomorrow</h3>
                  <span className="price">2020-2021</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
              <a className="btn-floating btn-large waves-effect waves-light" href="https://www.leadershiptomorrowseattle.org/" target="_blank"  rel="noopener noreferrer"><i className="material-icons">info</i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default LTSea;

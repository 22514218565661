import React, { Component } from "react";
import campus from '../../img/pennapps-campus.jpg';
import letter from '../../img/pennapps.jpg';
import screens from '../../img/pennapps-screens.jpg';

import meet from '../../img/pennapps-meet.jpg';
import me from '../../img/pennapps-me.jpg';
import wbenc from '../../img/pennapps-wbenc.jpg';
import teach from '../../img/pennapps-teach.jpg';
import c4p from "../../img/pennapps-c4p.jpg";

class PennApps extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
                  PennApps Fellows is a student-run non-profit organization that pairs talented students from around the country
                  to Philadelphia tech companies & startups for a 12-week summer internship program. Fellows work closely with a
                  local startup, get plugged into the Philly tech scene, and meet some of the coolest and brightest people in the
                  country. I was one of 14 PennApps Fellows in the class of 2015: my very first internship after finishing my
                   freshman year in college. Living in the same apartment building,
                  exploring Philadelphia, volunteering, attending meetups, sharing meals, karaoking, and partaking in self-organized "hack nights" together,
                  we forged strong bonds and memories that I will always cherish. Each of our internships centered around developing technologies that mobilize early stage startups.
    </p>
    <img src={letter} className="centering" draggable="false" alt="PennApps Fellows acceptance"/>
    <p className="description">
                I was paired with <a href="https://www.crunchbase.com/organization/curiousbee" target="_blank" rel="noopener noreferrer">CuriousBee</a>, a
                startup with a mission to inspire life-long learning through a technical platform for listing,
                searching, and registering for in-person classes. Philadelphia's strong culture of education and local camaraderie made it an ideal location to pilot.
                As one of four members of the team (the others are the CEO, CTO, and COO),
                I was given the keys to production to implement any improvements that I saw and that the team approved of.
                I led the design, implementation, and deployment of site overhauls and features. I
                rebuilt the site architecture as we transitioned from the CakePHP framework to Symfony framework, leveraging the similarities between
                each framework's Model-View-Controller design pattern. I enhanced the user onboarding experience through conducting research,
                reading case studies, prototyping, and gauging telemetry.
      </p>
      <img src={screens} className="centering" width="600" draggable="false" alt="mockups of site"/>
      <p className="description">
                 All of my work was done remotely. CuriousBee did not have an office, so I spent my days working in various buildings, libraries,
                 and gardens at the University of Pennsylvania.
                 It was difficult in the initial onboarding and setup phase. My mentor and I would spend hours on Hangouts debugging
                 my installation by sending console commands, screenshots, logs, and outputs back and forth.
      </p>
      <p className="description">
                 Community involvement is a major pillar of the PennApps Fellowship. On Monday nights, we served as Teaching Assistants for the
                 Interdisciplinary Innovation program by <a href="http://pennhealthx.com/" target="_blank" rel="noopener noreferrer">PennHealthX</a>, where we mentored PennMed students as they
                 develop Ruby on Rails applications. We participated in OpenAccess Philadelphia and were invited into the discussion on how the local
                 tech scene is helping tackle community issues. At CodeForPhilly, we mentored others and met with civic leaders. Additionally, we attended
                 conferences such as LibertyJS to learn about the latest development trends and meet other developers.
      </p>
      <p className="description">
                  Altogether, the PennApps Fellowship gave me an impeccable start to my career: a summer of empowerment, exploration, learning, friendship, and
                  outreach. Outside of this fellowship, I attended the <a href="http://www.wbenc.org/student-entrepreneur-program/" target="_blank" rel="noopener noreferrer">WBENC Student Entrepreneurship Program</a> in Austin, Texas.
      </p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Y89bKXHt3Mk" frameBorder="0" className="centering" title="WBENC Student Entrepreneur Program" allowFullScreen></iframe>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={meet} width="300" height="200" draggable="false" alt="group meeting"/></a>
          <a className="carousel-item" href="#two!"><img src={wbenc} width="300" height="200" draggable="false" alt="WBENC sign"/></a>
          <a className="carousel-item" href="#three!"><img src={teach} width="300" height="200" draggable="false" alt="teaching in Penn HealthX"/></a>
          <a className="carousel-item" href="#four!"><img src={me} width="300" height="200" draggable="false" alt="me at UPenn"/></a>
          <a className="carousel-item" href="#five!"><img src={c4p} width="300" height="200" draggable="false" alt="local meetup"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter industry" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={campus} alt="UPenn campus" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>PennApps Fellowship</span>
                <br/>
                <span className="subtitle-proj">Tech Startup Internship</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>PennApps Fellowship</h3>
                  <span className="price">Summer 2015</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
              </div>
            </div>
          </div>

      );
  }
}
 export default PennApps;

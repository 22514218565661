import React, { Component } from "react";
import '../css/home.css';

import welcomeVert from '../img/welcome-vert.jpg';
import welcomeHoriz from '../img/welcome.jpg';
import globe from '../img/internet.svg';
import islands from '../img/islands.jpg';
import Navbar from './Navbar-pixar';

import FadeIn from 'react-fade-in';
import '../css/gallery-materialize.min.css';
import '../css/portfolio.css';
import 'materialize-css';

import LTSea from './projects/LTSea.js';
import Google from './projects/Google.js';
import Workay from './projects/Workay.js';
import Microsoft from './projects/Microsoft.js';
import CornellEngLead from './projects/CornellEngLead.js';
import GoogleCodeU from './projects/GoogleCodeU.js';
import InvisibleWomen from './projects/InvisibleWomen.js';
import CornellEMS from './projects/CornellEMS.js';
import BigRedHacks from './projects/BigRedHacks.js'
import RHoK from './projects/RHoK.js';
import Entropy from './projects/Entropy.js';
import WICC from './projects/WICC.js';
import OCaml from './projects/OCaml.js';
import Startup from './projects/threeDayStartup.js';
import PennApps from './projects/PennApps.js';
import ACSStory from './projects/ACSStory.js';
import AdaDev from "./projects/AdaDev";
import MSPM from "./projects/MSPM";
import Jubilee from './projects/Jubilee.js';
import EightParables from './projects/EightParables.js';

declare var $ : any;

$(window).scroll(function(e){ 
    var $el = $('.pixarSticky'); 
    var isPositionFixed = ($el.css('position') == 'fixed');
    if ($(this).scrollTop() > 2811 && !isPositionFixed){ 
      $el.css({'position': 'fixed', 'top': '0px', 'marginTop':'64px'}); 
    }
    if ($(this).scrollTop() < 2811 && isPositionFixed){
      $el.css({'position': 'static', 'top': '0px', 'marginTop':'0px'}); 
    } 
  });

class home extends Component {
    componentDidMount(){
	
	var categories = $('nav .categories-container');
    if (categories.length) {
      categories.pushpin({ top: categories.offset().top });
      var $links = categories.find('li');
      $links.each(function() {
        var $link = $(this);
        $link.on('click', function() {
          $links.removeClass('active');
          $link.addClass('active');
          var hash = $link.find('a').first()[0].hash.substr(1);
          var $galleryItems = $('.gallery .gallery-item');

          $galleryItems.stop().addClass('gallery-filter').fadeIn(100);

          if (hash !== 'all') {
            var $galleryFilteredOut = $galleryItems.not('.' + hash).not('.all');
            $galleryFilteredOut.removeClass('gallery-filter').hide();
          }

          // transition layout
          $masonry.masonry({
            transitionDuration: '.3s'
          });
          // only animate on layout
          $masonry.one( 'layoutComplete', function( event, items ) {
            $masonry.masonry({
              transitionDuration: 0
            });
          });
          setTimeout(function() {

          $masonry.masonry('layout');
          }, 1000);
        });
      });
    }

		// Masonry Grid
    var $masonry = $('.gallery');
    $masonry.masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.gallery-filter',
      // use element for option
      columnWidth: '.gallery-filter',
      // no transitions
      transitionDuration: 0
    });
    // layout Masonry after each image loads
    $masonry.imagesLoaded(function() {
      $masonry.masonry('layout');
    });
    $('a.filter').click(function (e) {
      e.preventDefault();
    });

		$('.carousel:not(.carousel-slider)').carousel({
      dist: 0,
      padding: 10
    });
		$('.carousel.carousel-slider').carousel({
      fullWidth: true,
      indicators: true,
      onCycleTo: function(el) {
        $('.nav-background img').removeClass('active');
        $('.nav-background img').eq($(el).index()).addClass('active');
      }
    });
		var onShow = function(el) {
      var carousel = el.find('.carousel');
      carousel.carousel({
        dist: 0,
        padding: 10
      });
    };
    $('.gallery-expand').galleryExpand({
      onShow: onShow,
      dynamicRouting: true,
    });
		$('ul.tabs').tabs();
    $('.tabs').tabs('updateTabIndicator');
  	}
    render(){
    return (
      <div>
        <Navbar/>
      <div className="circle-reveal-intro">
      <div className="fixed-wrapper">
        <div className="circle-reveal-wrapper header light">
          <div className="circle-background white"></div>
          <div className="header-wrapper row valign-wrapper">
            <div className="col s12 m10">
              <div className="row" id="intro-container">
                <div className="col l7 m12 s12">
                  <span className="tagline" style={{display:"inline"}}><h2 style={{display:"inline"}}>Hi, I'm Jerica.</h2></span> &nbsp;&nbsp;&nbsp;<span style={{color:"black"}}><i>(Soul #7,431,643,192!)</i></span>
                  <br/><br/>
                  <span id="subtag-intro">
                    I've been a citizen of the Earth for quite some time now, and though I've mostly gotten the hang of this thing called <i>living</i>, sometimes, I feel like a lost soul.
                    <br/><br/>
                    
                    My <i>easily exciteable</i> and <i>curious</i> personality traits (and probably too much time spent in the Hall of Everything) have cultivated a diverse array of interests:<br/>
                    <b>technology</b>, <b>art</b>, <b>social impact</b>, <b>leadership</b>, <b>filmmaking</b>, <b>business</b><br/>... and so much more.<br/><br/>They all tug at me so strongly that I struggle to reconcile a single path forward without compromising any one of them.
                  </span><br/>
                <img src={islands} width="540"/>
                </div>
                <div className="col l5 m12 s12" id="welcome-container">
                  <img src={welcomeVert} className="centered" draggable="false" width="350" id="welcome-vert" alt="portrait"/>
                  <img src={welcomeHoriz} className="centered" draggable="false" height="300" id="welcome-horiz" alt="portrait"/>
                </div>

              </div>
              <button className="read-more"><i className="icon-caret-down"></i></button>
            </div>
          </div>
        </div>

        <div className="circle-reveal-wrapper header" id="circle2">
          <div className="circle-background collage"></div>
          <div className="header-wrapper row valign-wrapper">
            <div className="col s12 m8">
              <h1><span role="img" aria-label="book">📖‍</span></h1>
              <span className="tagline">
                <strong>
                  <span id="subtag-second">It's times like these when I </span>  <br/><br/>
                  <span className="categs"><b>remember who I am:</b></span><br/><br/>
                  <span id="subtag-second">my story, my spark, and the projects that ignited it.</span>
                  <br/><br/>
                </strong>
              </span>
              <button className="read-more"><i className="icon-caret-down" id="down2"></i></button>
            </div>
          </div>
        </div>
      </div>


      <div className="section background-layer valign-wrapper" id="circle3">
        <div className="container">
          <div className="row">
            <h2 className="section-title">...this is my spark.</h2>
            <img src={globe} width="400" style={{display:"block",margin:"auto",maxWidth:"90%"}} alt="globe"/>
            <h2 className="section-title" style={{textAlign:"center", fontSize:"30px"}}>Documenting , innovating , cherishing</h2>
            <h2 className="section-title" style={{textAlign:"center", fontSize:"25px"}}> my story and others',</h2>
            <h2 className="section-title" style={{textAlign:"center", fontSize:"25px"}}> on our shared pale blue dot.</h2><br/>
            <h2 className="section-title" style={{textAlign:"center", fontSize:"18px"}}><i> Outputs may vary.</i></h2>
          </div>
        </div>
      </div>
    </div>
    <div>
             <nav className="nav-extended pixarSticky">
		      <div className="categories-wrapper lighten-1" style={{marginTop:"0px"}}>
		        <div className="categories-container">
		          <ul className="categories container">
		            <li className="active"><a href="#all">All</a></li>
		            <li><a href="#industry">Industry</a></li>
		            <li><a href="#personal">Personal Projects</a></li>
		            <li><a href="#class">Academic Projects</a></li>
                    <li><a href="#film">Film</a></li>
		          </ul>
		        </div>
		        </div>
		        </nav>

		    <div id="portfolio" className="section gray" style={{paddingTop:"20px"}}>
		      <div className="container">
		        <div className="gallery row">
							<FadeIn transitionDuration="800">
                <AdaDev />
								<LTSea />
                <MSPM />
		        		<Google />
		          	<Workay />
                      <EightParables />
								<CornellEngLead />
								<ACSStory />
		          	<WICC />
		          	<Microsoft />
                      
								<Jubilee />
		          	<InvisibleWomen />
								<GoogleCodeU />
								<BigRedHacks />
								<RHoK />
		          	<CornellEMS />
		          	<Entropy />
                <OCaml />
                <Startup />
								<PennApps />
								</FadeIn>
		          </div>
		          </div>
		        </div>

		    </div>  
              
       </div>
    );
    }
}

export default home;

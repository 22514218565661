import React, { Component } from "react";
import projEMS from '../../img/proj-ems.jpg';

import ems1 from '../../img/ems1.jpg';
import ems2 from '../../img/ems2.png';
import ems3 from '../../img/ems3.png';
import ems4 from '../../img/ems4.png';
import ems5 from "../../img/ems5.png";

class CornellEMS extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Cornell Emergency Medical Service (EMS) is a student-operated, New York State certified, first response EMS agency. EMS units are responsible for keeping track of data related to emergency calls and responses. Most of the data is recorded on paper during an emergency response, with remaining information to be filled out in a Microsoft Excel file shortly after the response is complete.
      </p>
      <p className="description">
      EMS units can benefit from access to data analytics, as it can help them detect potential concerns in their service, allocate resources according to demand, and prepare for the future based on trends.
      </p>
      <p className="description">
        I worked with a team in <a href="https://www.cs.cornell.edu/courses/cs5150" target="_blank" rel="noopener noreferrer">CS 5150: Software Engineering</a> to help realize this potential. We believed that a software product could be a catalyst for Cornell EMS in serving their mission through their daily operations: ultimately helping them save lives and serve citizens better. The project came to life as the course progressed: we learned and applied the holistic process of specifying, designing, building, testing, and delivering reliable software systems. The majority of us have taken or served as Teaching Assistants for <a href="http://jeffrz.com/info3300/" target="_blank" rel="noopener noreferrer">CS/INFO 3300: Data-Driven Web Applications</a>, which emphasizes the enhanced depth, communication, and artistry gleaned from examining data in a visual format.
      </p>
      <p className="description">
        A key principle we learned is that client satisfaction is the sole success metric of software projects. It informs every other aspect of the project—security, features, design, accessibility, reliability, performance, and more.
      </p>
      <p className="description">
        We worked closely with a member of Cornell EMS in every stage of the project to ensure that what we were creating aligned with their goals and integrated with their current processes. In our client meetings, we did much more listening than we did presenting. We learned about Cornell EMS’s internal policies, practices, key metrics, and data privacy considerations that must keep in compliance. The integration of these factors with our team’s technical expertise continually shaped and refined the project over the semester.
      </p>
      <p className="description">
      The feasibility study and project plan that we developed early in the process helped us stay on track to deliver a fully-functional software project by the end of the semester, that the entire Cornell EMS squad was excited to implement! The data-driven dashboard’s visualizations and analysis tools help Cornell EMS understand and make important decisions about the squad’s performance. EMS members can import data through a Microsoft Excel file upload functionality. Then, the file contents are processed to be presented in data visualizations that the team members can examine and filter. This dashboard was designed to supplement their current process, so that they can maintain their current practices that must stay in compliance.
      </p>
      <p className="description">
        <b>Frontend</b>: React, Charts.js, Materialize <br/>
        <b>Dependency management</b>: Node.js, Yarn <br/>
        <b>Database</b>: MongoDB
      </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={ems1} width="300" height="200" draggable="false" alt="screenshot"/></a>
          <a className="carousel-item" href="#two!"><img src={ems2} width="300" height="200" draggable="false" alt="screenshot"/></a>
          <a className="carousel-item" href="#three!"><img src={ems3} width="300" height="200" draggable="false"  alt="screenshot"/></a>
          <a className="carousel-item" href="#four!"><img src={ems4} width="300" height="200" draggable="false" alt="screenshot" /></a>
          <a className="carousel-item" href="#five!"><img src={ems5} width="300" height="200" draggable="false" alt="screenshot" /></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
          <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter class"  onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={projEMS} alt="EMS" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>EMS Data Dashboard</span>
                <br/>
                <span className="subtitle-proj">Software Engineering</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>EMS Dashboard</h3>
                  <span className="price">Spring 2017</span>
                </div>
                {this.state.projtext}
              </div>
            </div>
          </div>

      );
  }
}
 export default CornellEMS;

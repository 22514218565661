import React, { Component } from "react";
import brh from '../../img/proj-brh.jpg';


import scene from '../../img/brh-scene.jpg';
import logos from '../../img/brh-logos.jpg';
import photos from '../../img/brh-photos.jpg';
import team from '../../img/brh-team.jpg';
import year14 from '../../img/brh-14.jpg';
import year15 from '../../img/brh-15.jpg';
import year16 from "../../img/brh-16.jpg";

class BigRedHacks extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
          BigRed//Hacks is Cornell University’s annual hackathon. Imagine a weekend-long experience where you are tasked with one thing:
          <br/>
          <span className="centering">create <i>something</i>.</span>
          <br/>
          “Hacking,” in this context, isn’t about breaking into systems, but breaking out of the confines of limited thinking. You’re surrounded by your peers and university students from all over the world, supported by corporate mentors, learning by doing, meeting other university students around the world, fed meals and snacks, and driven by the adrenaline rush of bringing into existence what can be.
      </p>
      <p className="description">
          The inaugural year of BigRed//Hacks was in 2014, the first semester of my freshman year. Despite being rudimentarily organized, with a Google Forms application, mass e-mail announcements throughout the weekend, and a very exhausted organizing team (consisting of just two people!), BigRed//Hacks 2014 was the spark that ignited the hacker ethos in me, and I’ve never looked back. I was invited to join the organizing team, with several goals in mind:
      </p>
      <ol>
        <li>
          Inspire similar sparks in future participants, on a broader global scale.
        </li>
        <li>
          Expand the CS culture at Cornell, which historically had more theoretical than practical emphasis.
        </li>
        <li>
          Increase campus presence, even to students outside of engineering/computer science.
        </li>
        <li>
          Lead a culture of diversity and inclusion so that minorities can realize their deserved place in this community.
        </li>
        <li>
          Reduce stress on the organizing team.
        </li>
      </ol>
      <img className="centering" src={logos} alt="hackathon logos" draggable="false"/>
        <p className="description">
          The next two years, I collaborated with a team to “hack” BigRed//Hacks. Among our creations were:<br/>
        </p>
            <ol>
            <li>Brainstorming and developing from scratch a hackathon management web application, integrated into our main event page. Functionalities included application submission, application review, admin event management, mentorship pairing, and live schedule broadcasting.</li>
            <li>The iconic clock tower-wifi signal logo that is still used today.</li>
            <li>Campus activities including a photo campaign, side project “hack” nights, and tabling (with Insomnia cookies).</li>
            <li>“Hackshop” events that teach skills such as front-end and back-end web development, design, mobile development, and hardware.</li>
            <li>Appointing certain organizing team members to serve specific shifts, so we all got enough sleep 😊</li>
            <li>Increases in many metrics: event applications, attendance, sponsorship, total funds raised, minority participation, social media engagement, and CS department recognition.</li>
            </ol>
        <p className="description">
          To this day, I’m so thankful to have stumbled into BigRed//Hacks 2014 and to have played a role in propelling it to the spectacular event it continues to be. It taught me to look for potential in every opportunity, and encouragement to create the better future I envision with everything I can bring, <s>even if</s> especially if it breaks the status quo.
        </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={scene} width="300" height="200" draggable="false" alt="Clark Atrium"/></a>
          <a className="carousel-item" href="#two!"><img src={photos} width="300" height="200" draggable="false" alt="campus publicity campaign"/></a>
          <a className="carousel-item" href="#three!"><img src={team} width="300" height="200" draggable="false" alt="org team"/></a>
          <a className="carousel-item" href="#four!"><img src={year14} width="300" height="200" draggable="false" alt="2014 site" /></a>
          <a className="carousel-item" href="#five!"><img src={year15} width="300" height="200" draggable="false" alt="2015 site"/></a>
          <a className="carousel-item" href="#six!"><img src={year16} width="300" height="200" draggable="false" alt="2016 site"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal"  onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={brh} alt="BigRed//Hacks" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>BigRed//Hacks</span>
                <br/>
                <span className="subtitle-proj">Web Development, Event Organizing, Branding</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>BigRed//Hacks</h3>
                  <span className="price">2014-2016</span>
                </div>
                {this.state.projtext}
              </div>
              <div className="gallery-action">

                <a className="btn-floating btn-large waves-effect waves-light" href="https://www.bigredhacks.com/" target="_blank" rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>

                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/bigredhacks/past-sites" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>

                <a className="btn-floating btn-large waves-effect waves-light" href="https://www.cs.cornell.edu/information/news/newsitem1626/big-red-hackathon-draws-400-students-across-northeast-cornell-campus" target="_blank" rel="noopener noreferrer"><i className="material-icons">article</i></a>
              </div>
            </div>
          </div>
      );
  }
}
 export default BigRedHacks;

import React, { Component } from "react";
import wicc from '../../img/proj-wicc.jpg';

import celebration from '../../img/wicc-celebration.jpg';
import gbody from '../../img/wicc-gbody.jpg';
import panel from '../../img/wicc-panel.jpg';
import photos from '../../img/wicc-photos.jpg';
import icecream from '../../img/wicc-icecream.jpg';
import posters from '../../img/wicc-posters.jpg';
import websites from '../../img/wicc-websites.png';
import logos from '../../img/wicc-logos.jpg';
import news from '../../img/wicc-news.jpg';
import div from '../../img/wicc-div.jpg';


class WICC extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Women in Computing at Cornell (WICC)’s mission is to make computing inclusive for all. By creating opportunities for personal and community growth, we are igniting a culture that enables people of all identities to maximize their potential to positively impact the future of tech.
      </p>

      <p className="description">
        WICC is among the most influential student organizations during my time at Cornell. For all eight semesters, I served on WICC’s executive board.
        In high school, I was one of 2-3 female students in my computer science classes. I loved the logical, creative, impactful aspects of computing and saw glimpses of my potential in the field. However, the inertia of feeling like a minority, along with admonishing from my parents and counselors, tainted that vision with doubt. I joined WICC's executive board as an impressionable freshman in Fall 2014, when the organization had been operating for only a year. Over the next four years, I observed powerful culture shifts alongside WICC's tremendous organizational growth. By promoting WICC's mission throughout my different board positions, I also thrived as a technologist, leader, and student.
      </p>

      <p className="description">
        In my first year on board, I worked alongside another board member to revamp WICC’s branding and develop a <a href="http://wicc.cornell.edu" target="_blank" rel="noopener noreferrer">brand new website</a> for WICC. Our design considerations were redesigning a logo that exudes inclusivity for people of all gender expressions, incorporating modern design and mobile responsiveness, and organizing media in an engaging, accessible way. I also began to volunteer to teach in WICC’s Girls Who Code program that serves students from all over Tompkins County.
      </p>
      <img src={websites} className="centering" draggable="false" alt="WICC website transformation"/>
      <img src={logos} className="centering" draggable="false" alt="WICC logo transformation"/>
      <p className="description">
        In my second and third years on board, I served as the career development co-director. This coincided with my work as a teaching assistant for Cornell’s Intro to Computing class and my efforts in organizing BigRed//Hacks. I saw a desire in the CS community—particularly for underclassmen—for education in topics such as web and mobile development, version control, design, the Grace Hopper celebration, resume development, and the internship application process. Personally, I had also faced an academic challenge, which I know is a common experience among many students, and saw a lack of <a href="https://www.instagram.com/p/BXEWBBhhkGf/" target="_blank" rel="noopener noreferrer">honest, vulnerable</a> discussions around failure. My mindset as an engineer empowered me to create what I see missing in the world. And that I did...
      </p>

      <p className="description">
        Here are slides from three events I was proud to co-produce and deliver. WICC has an archive of resources from past events, found <a href="https://wicc.cornell.edu/#/resources" target="_blank" rel="noopener noreferrer">here</a>!
      </p>

      <p className="description">
        <iframe title="Your first SWE internship" className="centering" src="https://docs.google.com/presentation/d/e/2PACX-1vTI6YHyUzlk-ZjCYfU6gHltI-AIOcOo85IzAgv6yFkkDOILvYKfJ80mluJ3UohLzeOWEIt6akQTUkPt/embed?start=false&loop=false&delayms=3000" frameBorder="0" width="80%" height="400"></iframe>
        <br/><br/>
        <iframe title="GHC guide presentation" className="centering" src="https://docs.google.com/presentation/d/e/2PACX-1vRgyxxjzko1Dsq1_GCyCfdW_pAr31TsZnU2MvaS50dibIJ10VORQEvm0FrqYhG2emxtBOPEt9-aHjbo/embed?start=false&loop=false&delayms=3000" frameBorder="0" width="80%" height="400"></iframe>
        <br/><br/>
        <iframe title="Design workshop" className="centering" src="../docs/wicc-designworkshop.pdf#zoom=25" frameBorder="0" width="80%" height="400"></iframe>
      </p>
      <br/>

      <p className="description">
        In my last year on board, I co-developed an allyship program. It was designed for members of the Cornell community who aren’t WICC board members, but are interested in learning and growing as allies in their own spheres of influence. We led seminars in allyship techniques, the importance of diversity, and project management. Participants joined in groups to brainstorm and direct a WICC event of their own. This program is currently restructured to the WICC “active membership” initiative.
      </p>
      <img src={news} className="centering" draggable="false" alt="News articles from Cornell CS and the Daily Sun"/>
      <p className="description">
        During my final semester before graduating, Cornell announced a historic milestone. Women will comprise <a href="https://cis.cornell.edu/55-percent-incoming-eng-students-interested-computer-science-are-women" target="_blank" rel="noopener noreferrer">half</a> of the incoming College of Engineering class, and incoming engineering students who declared interest in computer science are <a href="https://cornellsun.com/2018/09/04/women-now-comprise-half-of-college-of-engineering-students/" target="_blank" rel="noopener noreferrer">55%</a> women. These statistics grew from the ~30% women CS majors at Cornell in 2014, when I matriculated, and has increased steadily each year. Upon hearing this news, I was thrilled and grateful. I’ve admired Dr. Anita Borg and her “50/50 by 2020” goal, and achieving it ahead of schedule within our university is remarkable. I’m especially grateful to have seen WICC’s organizational growth and its role in transforming of the CS culture at Cornell from the inside out. From career development, academics, mentorship, community discussions, faculty relations, corporate relations, outreach, and branding, every board member contributed in meaningful ways that made ripple effects of inspiration to the community.
      </p>
      <img src={div} className="centering" draggable="false" alt="Diversity vs equity vs justice infographic"/>
      <p className="description">
        Honestly, the statistics weren’t our focus. You won’t find them in our mission statement. Rather, the proportion rose organically as a result of small actions, in organizations such as WICC and in the greater community, that propelled incremental culture change. It was a privilege to observe and experience it firsthand: all it takes is one WICC event that can lead to meeting a mentor, bonding with faculty, applying to your dream career, making lifelong friends, standing up to a microaggression, or changing your perception on the importance of diversity. Of course, there were necessary growing pains and some difficult community discussions along the way. Through my time in WICC, I learned to lead from the heart and mind while asking the right questions, especially why this work matters: not for the metrics or the mantra “diverse teams build better products,” but because of the core belief that people matter, and a better world is one where the builders and users of technology include all people and identities.
      </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={celebration} width="300" height="200" draggable="false" alt="end of year celebration"/></a>
          <a className="carousel-item" href="#two!"><img src={posters} width="300" height="200" draggable="false" alt="student spotlights"/></a>
          <a className="carousel-item" href="#four!"><img src={icecream} width="300" height="200" draggable="false" alt="ice cream social"/></a>
          <a className="carousel-item" href="#four!"><img src={gbody} width="300" height="200" draggable="false" alt="mental health general body meeting"/></a>
          <a className="carousel-item" href="#six!"><img src={panel} width="300" height="200" draggable="false" alt="panelists for internships" /></a>
          <a className="carousel-item" href="#seven!"><img src={photos} width="300" height="200" draggable="false" alt="publicity campaign"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
      <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal"  onClick={this.handleClick}>
        <div className="gallery-curve-wrapper">
          <div className="gallery-cover gray">
            <img className="responsive-img" src={wicc} alt="WICC" draggable="false"/>
          </div>
          <div className="gallery-header">
            <span>Women in Computing at Cornell</span>
            <br/>
            <span className="subtitle-proj">Organizational Leadership, Web Development, Branding</span>
          </div>
          <div className="gallery-body">
            <div className="title-wrapper">
              <h3>WICC</h3>
              <span className="price">2014-2018</span>
            </div>

            {this.state.projtext}

          </div>
          <div className="gallery-action">
            <a className="btn-floating btn-large waves-effect waves-light" href="https://wicc.cornell.edu" target="_blank"  rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>
          </div>
        </div>
      </div>

      );
  }
}
 export default WICC;

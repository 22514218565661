import React, { Component } from "react";
import jubilee from '../../img/proj-jubilee.jpg';

import instaPost from '../../img/jubilee-insta.jpg';

import meworking from '../../img/jubilee-produc.jpg';
import family from '../../img/jubilee-fam.jpg';
import jars from '../../img/jubilee-jars.jpg';
import onSet from '../../img/jubilee-onset.jpg';
import team from "../../img/jubilee-kiki.jpg";
import la from "../../img/jubilee-la.jpg";
import fly from "../../img/jubilee-fly.jpg";

class Jubilee extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
      Jubilee Media started with a single idea: to create a movement of empathy for human good. With stories captured in film, an internet platform, and unrelenting belief that ordinary people can do extraordinary things, Jubilee has reached awe-inspiring heights.
      </p>
      <p className="description">
      I’ve been a long-time fan of Jubilee since I was in high school. To this day, through artistic and organizational changes, their core mission has persisted: to bridge people together and inspire love through compelling stories that create connection, challenge assumptions, and touch the soul. Back before I developed the courage to own my story, I found solace in Jubilee’s. Themes of mental health, expectations of Asian-American immigrant children, coping with loss, and the hunger to live for something greater were not freely open for discussion in my immediate circle, but I found represented with grace and authenticity by Jubilee. I knew since then how stories can be the basis of profound connection. No matter how different we might seem, and even if we might never meet, stories can help us understand one another on a deeper level and come to see our shared human condition.
      <br/><br/>
        <img src={instaPost} className="centering" draggable="false" alt="Jublee fellow announcement instagram post" maxwidth="100%"/>
      </p>
      <p className="description">
        When I applied to the 2018 Jubilee Fellowship, a two-week mentorship program for aspiring filmmakers, I was approaching a turning point in my life. I was about to graduate from Cornell and continue my high-visibility tech career, wondering where the path will lead. The past two years, I singlehandedly wrote, directed, produced, shot, and edited two meaningful short films. I could not shake off the intense curiosity I had in the transformative power of storytelling and embedding Jubilee's ethos in my work as an engineer, and potentially more.
      </p>
      <p className="description">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/KSU2EWzP58M" frameBorder="0" className="centering" title="With Love" allowFullScreen></iframe>
      </p>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={meworking} width="300" height="200" draggable="false" alt="on set backstage"/></a>
          <a className="carousel-item" href="#two!"><img src={family} width="300" height="200" draggable="false" alt="BTS for the group pic"/></a>
          <a className="carousel-item" href="#three!"><img src={jars} width="300" height="200" draggable="false" alt="encouragement jars"/></a>
          <a className="carousel-item" href="#four!"><img src={onSet} width="300" height="200" draggable="false" alt="on set"/></a>
          <a className="carousel-item" href="#five!"><img src={team} width="300" height="200" draggable="false" alt="team at Jubilee office"/></a>
          <a className="carousel-item" href="#six!"><img src={la} width="300" height="200" draggable="false" alt="la sunset view" /></a>
          <a className="carousel-item" href="#seven!"><img src={fly} width="300" height="200" draggable="false" alt="plane flying into LA" /></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter produce film" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={jubilee} alt="Jubilee Media Fellowship" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Jubilee Media Fellowship</span>
                <br/>
                <span className="subtitle-proj">Producing</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Jubilee Fellowship</h3>
                  <span className="price">Summer 2018</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://www.youtube.com/user/jubileeProject" target="_blank"  rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default Jubilee;

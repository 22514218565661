import React, { Component } from "react";
import eightp from '../../img/proj-eightparables.jpg';

import signs from '../../img/8p-signs.jpg';
import aerial from '../../img/8p-aerial.jpg';
import boom from '../../img/8p-boom.jpg';
import lookbook from '../../img/8p-lookbook.jpg';
import group from '../../img/8p-group.jpg';
import laundromat from '../../img/8p-laundromat.jpg';
import table from '../../img/8p-table.jpg';


class EightParables extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        <i>"Making soulful films that make you smile a little, tear a little; films that touch our world at a soul level and bring a restorative touch; films of faith, hope and love."</i>
        <br/><br/>
        It was an honor to join the first Eight Parables film production retreat in San Francisco. I served as the Art Director, elevating the story through wardrobe, makeup, and production design.

        Our short film <i>Dirty Laundry</i>, created in partnership with <a href="https://www.mobilizelove.org/" target="_blank" rel="noopener noreferrer">Mobilize Love</a>, was publicly released Easter 2022. You can watch it below!<br/><br/>
        <iframe title="Dirty Laundry trailer" className="centering" src="https://player.vimeo.com/video/700345501" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
          <br/><br/>
        <b>Synopsis</b>
          <br/>
          <i>When there is a cost to forgive, and the cost is you.</i> Set in a laundromat in the gritty Tenderloin neighborhood in San Francisco, this is a story about the irreversible mistakes we make, the people we hurt, and the dilemma of forgiveness we seek. A serendipitous encounter at the laundromat led Dylan and Shannon to bond over their compassion and love for helping marginalized members of society. Their compassion was tested with the highs and lows and toils of life, and ultimately coming face to face with their own brokenness. Inspired by a true story.
        <br/><br/>
        <b>Honors</b>
          <br/>
          <i>Best Faith-Based Short</i> — <a href="https://www.burbankfilmfest.org/2021-winners" target="_blank" rel="noopener noreferrer">2021 Burbank International Film Festival</a>
          <br/>
          <i>Best Faith-Based Feature/Short</i> — <a href="https://walesfilmfestival.com/" target="_blank" rel="noopener noreferrer">2021 Wales International Film Festival</a>
          <br/>
          <i>Official Selection</i> — <a href="https://bravemaker.com/film-fest/" target="_blank" rel="noopener noreferrer">2022 BraveMaker Film Festival</a>

      </p>
      <br/>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={group} width="300" height="200" draggable="false" alt="group shot at bobaguys"/></a>
          <a className="carousel-item" href="#two!"><img src={laundromat} width="300" height="200" draggable="false" alt="laundromat BTS"/></a>
          <a className="carousel-item" href="#four!"><img src={table} width="300" height="200" draggable="false" alt="group debriefing"/></a>
          <a className="carousel-item" href="#five!"><img src={lookbook} width="300" height="200" draggable="false" alt="aerial bobaguys shot"/></a>
          <a className="carousel-item" href="#six!"><img src={aerial} width="300" height="200" draggable="false" alt="aerial bobaguys shot"/></a>
          <a className="carousel-item" href="#seven!"><img src={boom} width="300" height="200" draggable="false" alt="me on boom duty"/></a>
          <a className="carousel-item" href="#eight!"><img src={signs} width="300" height="200" draggable="false" alt="laundromat signs"/></a>
        </div>
      </div>

      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter art film" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={eightp} alt="Dirty Laundry movie poster" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Eight Parables</span>
                <br/>
                <span className="subtitle-proj">Art Direction, Production Design</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Eight Parables</h3>
                  <span className="price">Winter 2019</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
              <a className="btn-floating btn-large waves-effect waves-light" href="https://www.eightparables.com/retreat" target="_blank"  rel="noopener noreferrer"><i className="material-icons">description</i></a>

              </div>
            </div>
          </div>

      );
  }
}
 export default EightParables;

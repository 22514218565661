import React from 'react';
 
const Error = () => {
    return (
       <div>
          <h1 style={{textAlign: 'center',marginTop:'80px'}}>404 Error: Page does not exist</h1>
       </div>
    );
}
 
export default Error;
import React, { Component } from "react";
import ocaml from '../../img/proj-ocaml.jpg';

import instr from "../../img/func-instr.png";
import lose from "../../img/func-lose.png";
import ai from "../../img/func-ai.png";
import desc from "../../img/func-des.png";

class OCaml extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        The goal of CS 3110-Data Structures &amp; Functional Programming is to help students become excellent programmers
        who can design and implement software that is elegant, efficient, and correct, and whose code can be maintained and reused. Beyond OCaml syntax,
        CS 3110 teaches that <b>programming</b> transcends <b>programming in a language</b>: that complicated artifacts can be broken down into small
        pieces, that you can study those small pieces and understand how they work in isolation, and that ultimately you can understand
        why their aggregation achieves broader goals. <i>Fun</i> is a keyword. 😉
      </p>
      <p className="description">
        My final project for CS 3110, developed with a team of 4, is an HTML5 canvas web-browser implementation of the game 2048 written exclusively in OCaml,
        using the <a href="http://ocsigen.org/js_of_ocaml/" target="_blank"  rel="noopener noreferrer">Js_of_ocaml compiler</a>. Key features are game state tracking,
        keyboard controls, evil/regular game mode toggling, and three AI auto-run implementations.
      </p>
      <p className="description">
        The project had substantial planning process, where we outlined the program architecture, system design, module design, data
        maintenance system, external dependencies, and a testing plan before writing any code. When the time came to code, we knew exactly what we were building and every building block along the way.
      </p>

      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={ocaml} width="300" height="200" draggable="false" alt="app screenshot"/></a>
          <a className="carousel-item" href="#two!"><img src={instr} width="300" height="200" draggable="false" alt="game instructions"/></a>
          <a className="carousel-item" href="#three!"><img src={lose} width="300" height="200" draggable="false" alt="game lose state"/></a>
          <a className="carousel-item" href="#four!"><img src={ai} width="300" height="200" draggable="false" alt="ai functionality" /></a>
          <a className="carousel-item" href="#five!"><img src={desc} width="300" height="200" draggable="false" alt="app description"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
    <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter class" onClick={this.handleClick}>
      <div className="gallery-curve-wrapper">
        <div className="gallery-cover gray">
          <img className="responsive-img" src={ocaml} alt="OCaml 2048" draggable="false"/>
        </div>
        <div className="gallery-header">
          <span>OCaml 2048</span>
          <br/>
          <span className="subtitle-proj">Data Structures and Functional Programming</span>
        </div>
        <div className="gallery-body">
          <div className="title-wrapper">
            <h3>OCaml 2048</h3>
            <span className="price">Fall 2016</span>
          </div>
          {this.state.projtext}
        </div>
        <div className="gallery-action">
          <a className="btn-floating btn-large waves-effect waves-light" href="../OCaml2048/" target="_blank"  rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>

          <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/jericahuang/cs3110-2048" target="_blank"  rel="noopener noreferrer"><i className="material-icons">code</i></a>
        </div>
      </div>
    </div>


      );
  }
}
 export default OCaml;

import React, { Component } from "react";
import adaTeaching from '../../img/proj-ada.jpg';


class AdaDev extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
      Ada’s mission is to prepare women and gender expansive adults to be software developers while advocating for inclusive and equitable work environments. Its revolutionary, first-of-its-kind, holistically supportive, and cost-free training program includes a collaborative learning environment, individualized support (mentors, tutors, childcare, mental healthcare, affinity groups), and an applied learning internship. Students emerge ready to enter the workforce as software developers while advancing diversity, equity, and inclusion in the industry.
      </p>
      <p className="description">
      At Ada, I had the honor of serving as an in-person classroom instructor for three cohorts of Adies. The curriculum is a fast-paced, intensive study of Python, Object-Oriented Programming, algorithmic strategies, Flask, HTML/CSS, JavaScript, and React with a series of complex individual and group projects. From coming in with no experience, students graduate having the skill to independently develop modern full-stack applications with algorithmic efficiency and technical polish. 
      </p>
      <p className="description">
      In addition to teaching, I developed new projects and original supplemental curricula that are not only educational, but fun and relevant. I hosted a series of advanced frontend development workshops and demos on artificial intelligence, machine learning, and data science.
      </p>
      <p className="description">
      Ada afforded me the opportunity to “wear multiple hats” to apply my diverse skillset for critical projects. I served as a product manager for curriculum development, where I led the editing, refinement, authoring, and timely launch of the core curriculum, integrating insights from diverse sources to inform changes to be made.
      </p>
      <p className="description">
      Throughout my tenure, I alongside my team completed intensive and continual diversity-equity-inclusion and anti-racism training to break down systemic barriers in the way of student and community success.
      </p>
      </div>
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter industry" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={adaTeaching} alt="Teaching" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Ada Developers Academy</span>
                <br/>
                <span className="subtitle-proj">Instruction, Software Engineering, PM</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Ada Developers Academy</h3>
                  <span className="price">2021-2023</span>
                </div>
                {this.state.projtext}

              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://www.adadevelopersacademy.org" target="_blank"  rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default AdaDev;

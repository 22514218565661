import React from 'react';
import '../css/home.css';

import about from '../img/about.jpg';
import codeshot from '../img/codeshot.jpg';
import film from '../img/film-usc.jpg';
import welcomeVert from '../img/welcome-vert.jpg';
import welcomeHoriz from '../img/welcome.jpg';
import globe from '../img/internet.svg';
import Navbar from './Navbar';

const home = () => {
    return (
      <div>
      <Navbar />
      <div className="circle-reveal-intro">
      <div className="fixed-wrapper">
        <div className="circle-reveal-wrapper header light">
          <div className="circle-background white"></div>
          <div className="header-wrapper row valign-wrapper">
            <div className="col s12 m10">
              <div className="row" id="intro-container">
                <div className="col l7 m12 s12">
                  <span className="tagline"><h2>Hi, I'm Jerica.</h2></span>
                  <span id="subtag-intro">
Standing at the intersection of engineering, business, and the humanities, I am creating a future of technology that optimizes human experiences and empowers business and social innovation.<br/>When I succeed, we all succeed.</span>
                  <div className="row" id="where-container">
                    <div className="col m5 offset-m1 s6">
                      <div id="where-now">
                        <span className="where-header">Now</span>
                        <ul className="where">
                          <li><a href="https://formation.dev/" target="_blank" rel="noopener noreferrer">Formation</a></li>  
                          <li><a href="https://www.inspiritai.com/" target="_blank" rel="noopener noreferrer">Inspirit AI</a></li>  
                        </ul>
                      </div>
                    </div>
                    <div className="col m5 s6">
                      <div id="where-prev">
                        <span className="where-header">Previous</span>
                        <ul className="where">
                          <li><a href="https://adadevelopersacademy.org/" target="_blank" rel="noopener noreferrer">Ada Developers Academy</a></li>
                          <li><a href="https://www.microsoft.com/about" target="_blank" rel="noopener noreferrer">Microsoft</a></li>
                          <li><a href="https://about.google/" target="_blank" rel="noopener noreferrer">Google</a></li>
                          <li><a href="https://www.cornell.edu/" target="_blank" rel="noopener noreferrer">Cornell University</a></li>
                          <li><a href="https://cinema.usc.edu/" target="_blank" rel="noopener noreferrer">USC School of Cinematic Arts</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col l5 m12 s12" id="welcome-container">
                  <img src={welcomeVert} className="centered" draggable="false" width="350" id="welcome-vert" alt="portrait"/>
                  <img src={welcomeHoriz} className="centered" draggable="false" height="300" id="welcome-horiz" alt="portrait"/>
                </div>

              </div>
              <button className="read-more"><i className="icon-caret-down"></i></button>
            </div>
          </div>
        </div>

        <div className="circle-reveal-wrapper header" id="circle2">
          <div className="circle-background collage"></div>
          <div className="header-wrapper row valign-wrapper">
            <div className="col s12 m8">
              <h1><span role="img" aria-label="book">📖‍</span></h1>
              <span className="tagline">
                <strong>
                  <span className="categs">Documenting</span> ,  <span className="categs">innovating </span>, <span className="categs">cherishing</span><br/>
                  <span id="subtag-second">my story and others',<br/>on our shared pale blue dot.</span>
                  <br/><br/>
                  <i>Outputs may vary.</i>
                </strong>
              </span>
              <button className="read-more"><i className="icon-caret-down" id="down2"></i></button>
            </div>
          </div>
        </div>
      </div>


      <div className="section background-layer valign-wrapper" id="circle3">
        <div className="container">
          <div className="row">
            <h2 className="section-title">...bridging liminal space</h2>
            <img src={globe} width="400" style={{display:"block",margin:"auto",maxWidth:"90%"}} alt="globe"/>
            <h2 className="section-title" style={{textAlign:"center"}}>between <i>no longer</i> and <i>not yet</i>.</h2>
          </div>
        </div>
      </div>
    </div>
         <div className="section light" id="pagelinks">
	      <div className="row">
	        <div className="col s12 m10 offset-m1 center">
	          <h2>See how:</h2>
	          <div className="row masonry-grid">
	            <div className="col s12 m8 offset-m2 l4">
	              <div className="card">
	                <div className="card-image">
	                  <a href="./about"><img src={about} alt="About link"/></a>
	                  <span className="card-title">About</span>
	                </div>
	              </div>
	            </div>
	            <div className="col s12 m8 offset-m2 l4">
	              <div className="card">
	                <div className="card-image">
	                  <a href="./portfolio"><img src={codeshot} alt="Projects link"/></a>
	                  <span className="card-title">Portfolio</span>
	                </div>
	              </div>
	            </div>
	            <div className="col s12 m8 offset-m2 l4">
	              <div className="card">
	                <div className="card-image">
	                  <a href="./film"><img src={film} alt="blog link"/></a>
	                  <span className="card-title">Film</span>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
       </div>
    );
}

export default home;
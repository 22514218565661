import React, { Component } from "react";
import FadeIn from 'react-fade-in';
import '../css/gallery-materialize.min.css';
import '../css/portfolio.css';
import 'materialize-css';

import Navbar from './Navbar';
import Jubilee from './projects/Jubilee.js';
import EightParables from './projects/EightParables.js';

declare var $ : any;


class Film extends Component {

	componentDidMount(){
		$(window).on('scroll.navbar', function () {
      var navbar = $('nav.navbar').first();
      if (!navbar.hasClass('solid')) {
          navbar.addClass('solid');
      }
    });

	var categories = $('nav .categories-container');
    if (categories.length) {
      categories.pushpin({ top: categories.offset().top });
      var $links = categories.find('li');
      $links.each(function() {
        var $link = $(this);
        $link.on('click', function() {
          $links.removeClass('active');
          $link.addClass('active');
          var hash = $link.find('a').first()[0].hash.substr(1);
          var $galleryItems = $('.gallery .gallery-item');

          $galleryItems.stop().addClass('gallery-filter').fadeIn(100);

          if (hash !== 'all') {
            var $galleryFilteredOut = $galleryItems.not('.' + hash).not('.all');
            $galleryFilteredOut.removeClass('gallery-filter').hide();
          }

          // transition layout
          $masonry.masonry({
            transitionDuration: '.3s'
          });
          // only animate on layout
          $masonry.one( 'layoutComplete', function( event, items ) {
            $masonry.masonry({
              transitionDuration: 0
            });
          });
          setTimeout(function() {

          $masonry.masonry('layout');
          }, 1000);
        });
      });
    }

		// Masonry Grid
    var $masonry = $('.gallery');
    $masonry.masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.gallery-filter',
      // use element for option
      columnWidth: '.gallery-filter',
      // no transitions
      transitionDuration: 0
    });
    // layout Masonry after each image loads
    $masonry.imagesLoaded(function() {
      $masonry.masonry('layout');
    });
    $('a.filter').click(function (e) {
      e.preventDefault();
    });

		$('.carousel:not(.carousel-slider)').carousel({
      dist: 0,
      padding: 10
    });
		$('.carousel.carousel-slider').carousel({
      fullWidth: true,
      indicators: true,
      onCycleTo: function(el) {
        $('.nav-background img').removeClass('active');
        $('.nav-background img').eq($(el).index()).addClass('active');
      }
    });
		var onShow = function(el) {
      var carousel = el.find('.carousel');
      carousel.carousel({
        dist: 0,
        padding: 10
      });
    };
    $('.gallery-expand').galleryExpand({
      onShow: onShow,
      dynamicRouting: true,
    });
		$('ul.tabs').tabs();
    $('.tabs').tabs('updateTabIndicator');
  	}
	render(){
		return (
	       <div>
          <Navbar/>
	        <nav className="nav-extended">
		      <div className="categories-wrapper lighten-1" id="categories-fixed">
		        <div className="categories-container">
		          <ul className="categories container">
		            <li className="active"><a href="#all">All</a></li>
		            <li><a href="#direct">Directing</a></li>
		            <li><a href="#produce">Producing</a></li>
		            <li><a href="#dp">Cinematography</a></li>
		            <li><a href="#art">Art Direction</a></li>
		          </ul>
		        </div>
		        </div>
		        </nav>

		    <div id="portfolio" className="section gray">
		      <div className="container">
		        <div className="gallery row">
							<FadeIn transitionDuration="800">
								<EightParables />
								<Jubilee />
							</FadeIn>
		          </div>
		          </div>
		        </div>

		    </div>
		)
	}
}

export default Film;

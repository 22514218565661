import React, { Component } from "react";
import msSign from '../../img/proj-mspm.jpg';


class MSPM extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Under construction    
      </p>
      
      </div>
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter industry" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={msSign} alt="Teaching" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Microsoft Commerce + Ecosystems</span>
                <br/>
                <span className="subtitle-proj">Engineering Program Management</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Microsoft</h3>
                  <span className="price">2018-2021</span>
                </div>
                {this.state.projtext}

              </div>
            </div>
          </div>

      );
  }
}
 export default MSPM;

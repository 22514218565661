import React, { Component } from "react";
import celpMag from '../../img/proj-celp.jpg';

import me from "../../img/celp-me.jpg";
import yesandLogo from "../../img/celp-yesandLogo.jpg";
import yesandCollage from "../../img/celp-yesand.jpg";
import mag from "../../img/celp-mag.jpg";
import results from "../../img/celp-results.jpg";
import wall from "../../img/celp-wall.jpg";
import gScholar from '../../img/celp-gscholar.jpg';
import skydive from '../../img/celp-skydive.jpg'
import graces from '../../img/celp-graces.jpg';
import grad from '../../img/celp-grad.jpg';
import hat from '../../img/celp-hat.jpg';
import maze from '../../img/celp-maze.jpg';
import yesAnd from '../../img/celp-ya.jpg';
import retreat from "../../img/celp-retrt.jpg";
import notoriousBIG from "../../img/celp-big.jpg";
import intro from '../../img/celp-intro.jpg';
import view from "../../img/celp-view.jpg";
import group from "../../img/celp-group.jpg";

declare var $ : any;

class CornellEngLead extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: "", uppertext:"", lowertext:""};
    this.handleClick = this.handleClick.bind(this);

  }
  componentDidMount(){
    $('ul.tabs').tabs();
    $('.tabs').tabs('updateTabIndicator');
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      uppertext:
      <div>
      <p className="description centering">
        <i>These are exciting times for engineers. Society's biggest challenges are increasingly met with technology, and science is nearly synonymous with innovation.</i>
        <br/><br/>
        <i>But technical skills alone are not enough. Turning ideas into solutions, bringing innovations to life, influencing governments and societies: these require engineers to step up as team members and leaders.</i>
      </p>
      <p className="description">
        When I first came to Cornell, I had never considered myself a leader. Many facets of my identity did not conform to the stereotypical “leader”: I’m an Asian-American woman who is studying in the engineering field, prefers listening over talking, is more comfortable staying quiet than disrupting the status quo, and carries a history tainted with adversity. However, Cornell Engineering is anything but conventional, and its motto, “Break the rules to do great things,” gave me hope. As I navigated an eventful first semester highlighted by BigRed//Hacks, 3-Day Startup, and joining WICC board, I knew the time had come for me to start living my life differently if I wanted to become the engineer and entrepreneur I felt strongly called to. At this point, the Cornell Engineering Leadership Program was in its second year of operation. When I learned about its year-long leadership certification program, especially its mission to “develop your unique capabilities to make an impact on the world and live a life of purpose and fulfillment,” everything aligned. Curiosity overtook fear.
      </p>
      <p className="description">
        Going through the program was like skydiving. When you’re on the plane over 10,000 feet above ground, looking down, about to take the leap, there’s a last remaining bit of fear and hesitancy holding you back. You know there's a risk ahead. Above the clouds, you can’t see the ground. You don’t know where you’ll land, and you can’t predict the journey you’re about to take to get there. But you remember why you got on the plane in the first place: the deep longing to expand your outlook and definition of self. You remember the inherent qualities in you that got you to where you are now, and can get you to where you want to go. The encouraging presence of fellow divers on the plane reassures you that you won't be alone. And with the fear and hesitancy, you do it anyway. You take the leap.
      </p>
      <p className="description">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/KffVHW3DvIM" frameBorder="0" className="centering" title="Teaching Courage" allowFullScreen></iframe>
      </p>
      <p className="description">
      <b>Free fall</b><br/>
        <i>
          You’re suddenly met with forceful gusts of wind blowing against you, roaring in your ears as you accelerate towards the earth. The strong, unfamiliar sensations send you into an initial state of shock, and you’re probably still flailing and screaming at the top of your lungs.
        </i>
        <br/><br/>
        The program began with a weekend-long retreat off campus, where we met our instructors, Erica and Werner, and fellow cohort members for the first time. I’ll never forget the first day, when Erica (who is a pro skydiver!) conveyed that we’ve all made a choice to be here, that leadership is ultimately a choice and a risk you must make when you want to have an intended impact. It’s not given by title or position, nor by any measure of identity privilege. It’s not always smooth sailing: at times, it’ll be scary, lonely, unfair, and uncertain. Above all, there is <b>no mold</b>: leadership looks different from person to person. Everyone can discover and hone their leadership style. In developing as leaders, we’re changing ourselves to change the world. I could feel my old ways of thinking undergoing radical transformation, and I knew I had just taken the first step in a new direction.
      </p>
      <div className = "row">
         <div className = "col m4 center"><img src={intro} draggable="false" alt="first night introductions"/></div>
         <div className = "col m4 center"><img src={view} draggable="false" alt="appreciating the view"/></div>
         <div className = "col m4 center"><img src={group} draggable="false" alt="group activity"/></div>
      </div>
      <p className="description">
        Erica and Werner made a promise that they <i>will</i> challenge us and put us in situations where we’ll be uncomfortable, but always intended for our growth. Their teaching ethos centers on experiential learning through exercises that might seem unconventional, but always have important leadership and personal inquiry lessons ingrained in them. Like the first breath of fresh air and wind rushing against our faces, the leadership exercises were immersive, unpredictable, and all-consuming, keeping us on our toes and pushing us out of our comfort zones. Throughout it all, we revealed our authentic selves and celebrated the individuality that makes each of us leaders with tremendous potential. By being vulnerable and taking emotional risks, we became a community.
        <br/><br/>
        Our collective jump out of the plane that retreat weekend continued into the spring semester, in the <i>Foundations of Engineering Leadership</i> course.
      </p>
      <br/>
      <p className="description">
      <b>Terminal Velocity</b><br/>
        <i>You start to gain body awareness and maneuver into your air position. Opening your eyes, you take in the expansive view of the world before you that you want to take every moment to savor. Despite falling at a faster speed than when you started, you feel more comfortable now. It almost feels… fun! You let out a wide grin, even a laugh. You feel brave enough to try turns, somersaults, and formations with your fellow divers. </i>
        <br/><br/>
        <i>Foundations of Engineering Leadership</i> is the first of two semester-long courses in the program. “Foundations” not only entails core managerial and team skills, but also deep self-knowledge. We delved deep to identify and articulate our strengths, core values, purpose, goals, and derailers: topics rarely brought up in a typical engineering classroom or workplace setting. This work completely changed how I saw myself and empowered me to <b>lead my own life</b>. I started to see that everything that made me who I am is to be celebrated: things about myself that I used to carry with shame. They’ve shaped the core values that serve as my life compass and painted a vision of the brighter future I want to create. They directly inform the <i>why</i>, the <i>purpose</i>, behind my decision to take a risk to lead for a cause bigger than myself, that can be a source of inspiration to others. These factors are what makes leaders <i>leaders</i>, not any measure of being the most extroverted, domineering, or privileged. I never had to compromise my individuality and conform to an inauthentic ideal. I can lead by just being me.
      </p>
        <img src={me} className="centering" draggable="false" alt="strengths report" maxwidth="100%"/>
      <p className="description">
        With all the confidence and courage we built, the work is far from over. Now comes our opportunity to apply it. The second segment of the program is <i>Applied Engineering Leadership</i>. From the foundation we built, we joined in teams with common vision and values to enact a Leadership Project that is original, meaningful, and will have measurable impact. My team resonated on the values of empathy and equity, especially in breaking stereotypes that both stifle our individuality and limit our potential by how we are perceived. We want people to celebrate the wholeness of who they are with all their complex and intersectional identities, and share it in a way that inspires others to see them that way too.
      </p>
      <div className = "row">
         <div className = "col m3 s6 center"><img src={yesandLogo} draggable="false" alt="yes and campaign logo"/></div>
         <div className = "col m3 s6 center"><img src={yesandCollage} draggable="false" alt="campaign collage"/></div>
         <div className = "col m3 s6 center"><img src={wall} draggable="false" alt="empathy wall"/></div>
         <div className = "col m3 s6 center"><img src={results} draggable="false" alt="project results"/></div>
      </div>
      <p className="description">
        We started working mid-spring through the summer to research, brainstorm, budget, plan, and apply for funding. Come fall semester, we hit the ground running to enact our project, getting support and leadership lessons every week from our class. We enacted two initiatives. First, a campus-wide photo campaign publicized <a href="http://www.facebook.com/YesAndCampaign" target="_blank" rel="noopener noreferrer">on social media</a> for reach within our participants' social circles, and potentially, to strangers around the world (like you!). Second, an empathy wall situated in a student hub for students and staff to pick up or write their own note of encouragement/"I feel this" for another community member, because as we all can relate, we have so much going on in our lives that can be hard to find solace in. From the huge engagement metrics we measured, we saw there was a real need for these values to be lived out on campus and in the world.
      </p>
      <p className="description">
        In the <i>Applied Engineering Leadership </i> classroom, we continued to learn important leadership and teamwork lessons that directly integrated with our project work. My team had a blast working on our project, though our success didn’t come without confronting our areas of improvement. We practiced giving and receiving feedback, managing conflict, holding each other accountable, assessing and shaping organizational culture, articulating ethical code, and influencing styles in pitches and project presentations.
      </p>
      <br/>
      <p className="description">
      <b>Re-boarding the plane</b><br/>
      <i>When you realize you’ve fallen to the appropriate altitude, you sense the adventure is ready to come to an end. You deploy your parachute. Gently descending to the ground, you catch your breath and process everything that just happened. This glorious view before you, the adventure that led up to it, and your breakthrough of personal barriers would never have happened if it wasn’t for your choice of courage and curiosity. Once you hit familiar ground, you let out a victory cheer alongside your fellow divers. You already can’t wait to board the plane again, this time signing up to fall from higher altitude.</i>
      </p>
      <p className="description">
        We “landed on the ground” at the conclusion of the fall semester with a final hurrah at a bittersweet, hope-inspiring graduation event. Every single cohort member was a changed individual since our first retreat, and every single team turned their own turbulence to clear skies. Though sad that the program has ended, we knew that this wasn’t the end of our leadership journeys. We’ll be entering a world that calls on us to solve society's most urgent problems and improve our quality of life. All of us had our own new planes to board.
      </p>
      <p className="description">
        As for me, I re-boarded that same plane the next year, as I was given the utmost honor of serving as a Teaching Assistant for the upcoming cohort. Up until now, I was primarily focused on my own growth as a leader, whereas this year I applied my learnings in service of the students’ growth. I worked in partnership with the instructors, helping to co-create the program to maximize each student’s journey into their own definition of a courageous, insightful, powerful leader. Check out one of that year’s leadership projects:<br/>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/LVW7tKsQNLU" frameBorder="0" className="centering" title="Untold" allowFullScreen></iframe>
      </p>
      <p className="description">
        Since then, I’ve boarded many planes that my former, hesitant self would never think of: social entrepreneurship explorations in Brazil, pursuing a PM career, filmmaking, organizing large-scale events, and even pageants. Now when I feel fear, I know I’m in the right place. It puts me at a crossroad where I will choose courage, remember why I do what I do, and take the leap.
      </p>
      <p className="description">
        The Engineering Leadership Program has since been gaining student interest and campus attention, and deservedly so! I shared about my experience in the program in <a href="../docs/EngLeadershipArticle.pdf" target="_blank"  rel="noopener noreferrer">Cornell Engineering Magazine</a> and at the 2016 Google Scholars Retreat (where I was awarded the Google Anita Borg Memorial Scholarship).
      </p>
      <div className = "row">
         <div className = "col m4 push-m1 s6 center"><img src={mag} draggable="false" alt="yes and campaign logo"/></div>
         <div className = "col m4 push-m3 s6 center"><img src={gScholar} draggable="false" alt="campaign collage"/></div>
      </div>
      <p className="description">
        At the time of writing this, I’ve completed my time in the Engineering Leadership Program and graduated from Cornell. Not a single moment has gone by that I haven’t been living by how the program changed me. Whatever next adventures I embark on, I know it’ll always be the wind beneath my wings.
      </p>
      <p className="description">
        <i>“Once you have tasted flight, you will forever walk the earth with your eyes turned skyward, for there you have been, and there you will always long to return.”</i>&nbsp;&nbsp; ― Leonardo da Vinci
      </p>
      </div>
      ,
      lowertext:
      <div>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={retreat} width="300" height="200" draggable="false" alt="Google Scholar poster"/></a>
          <a className="carousel-item" href="#two!"><img src={skydive} width="300" height="200" draggable="false" alt="skydiving with inspiration from Erica!" /></a>
          <a className="carousel-item" href="#three!"><img src={maze} width="300" height="200" draggable="false" alt="maze activity"/></a>
          <a className="carousel-item" href="#four!"><img src={graces} width="300" height="200" draggable="false" alt="student group activity"/></a>
          <a className="carousel-item" href="#five!"><img src={yesAnd} width="300" height="200" draggable="false" alt="leadership project"/></a>
          <a className="carousel-item" href="#six!"><img src={hat} width="300" height="200" draggable="false" alt="graduation cap"/></a>
          <a className="carousel-item" href="#seven!"><img src={notoriousBIG} width="300" height="200" draggable="false" alt="class of 2015 <3"/></a>
          <a className="carousel-item" href="#eight!"><img src={grad} width="300" height="200" draggable="false" alt="graduation in front of our bulletin board"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal class"  onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={celpMag} alt="Cornell Engineering Leadership Program" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Cornell Engineering Leadership Program</span>
                <br/>
                <span className="subtitle-proj">Leadership, Teaching</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Engineering Leadership</h3>
                  <span className="price">2015-2018</span>
                </div>
                {this.state.uppertext}

                  {this.state.lowertext}
              </div>
              <div className="gallery-action">
              <a className="btn-floating btn-large waves-effect waves-light" href="../docs/EngLeadershipArticle.pdf" target="_blank"  rel="noopener noreferrer"><i className="material-icons">menu_book</i></a>
              <a className="btn-floating btn-large waves-effect waves-light" href="https://www.engineering.cornell.edu/students/undergraduate-students/special-programs/engineering-leadership-programs/engineering" target="_blank"  rel="noopener noreferrer"><i className="material-icons">info</i></a>

              </div>
            </div>
          </div>
      );
  }
}
 export default CornellEngLead;

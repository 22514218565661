import React, { Component } from "react";

class Footer extends Component{
  render(){
    return (
        <footer className="page-footer">
          <div className="container">
              <div id="sdg">
                <p>Thanks for visiting. <span role="img" aria-label="wave">👋🏼</span></p>
                <p>Made with &hearts; using <a href="https://materializecss.com/" target="_blank" rel="noopener noreferrer">Materialize</a> and <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a>.</p>
                <p><i>Soli Deo Gloria.</i></p>
              </div>
          </div>
      </footer>
      );
  }
}
 export default Footer;
import React, { Component } from "react";
import hooli from '../../img/proj-hooli.jpg';

import group from '../../img/codeu-all.jpg';
import screenshot from '../../img/codeu-s1.png';
import team from '../../img/codeu-team.jpg';
import aboutcodeu from '../../img/codeu-abt.jpg';
import banner from "../../img/codeu-bann.jpg";
import commit from "../../img/codeu-commit.png";

class GoogleCodeU extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        CodeU is a year-long, invite-only developmental program for high-potential students throughout the U.S. who have undergone Google’s technical hiring process. The program kickstarted virtually in January and culminated with a week-long summit at Google’s Mountain View Headquarters in August. The program entails:
      </p>
        <p className="description">
        <b>Technical development</b>:  All participants completed an intensive online data structures and algorithms course using Java, taught through the Flatiron School. Taking it alongside my academic courseload helped reinforce concepts and strengthen my programming skills. The course transitioned into applying concepts to build the components of a search engine for Wikipedia pages: the web crawler, indexer, database structure, and information retrieval backend. After completing the course, we joined in groups of 3 to further develop and iterate on it however we’d want.
        <br/><br/>
        <b>Mentorship</b>:  Throughout the course of the program, I met with a Google Engineer mentor monthly over Hangouts. He also studied at Cornell, and I appreciated relating to his CS journey. We discussed topics including career direction, interview tips, academic advice, succeeding in internships, recent events in the tech industry, and life at Google. He and a few other mentors offered to conduct technical mock interviews with feedback. The realistic simulation helped tremendously for when the real interview came.
        <br/><br/>
        <b>Community and networking</b>: All participants gathered in a Slack community, which fostered micro-communities in channels centered on schools, locations, interests, and more. Throughout the year, the community enabled us to collaborate on the Flatiron course and even establish new professional connections. All the excitement and hard work built up to the weeklong summit, where we could convene in person.

      </p>
      <p className="description">
        I’d always seen media and comments describing Google’s campus as quirky, playground-like, and whimsical, almost like something out of a dream. Experiencing it firsthand made my goal of succeeding in the tech industry, whether at Google or not, ever more tangible. We had a full week of tours, networking sessions, leadership and career development workshops, time to work on our group project, and a final project showcase.
      </p>

      <p className="description">
        My team’s final project is still one of my most memorable projects to date. After completing the course, our base implementation was a command line-based Search Engine. We saw great potential in developing a web-based user interface, integrating natural language processing, and implementing a question-answering functionality. So much creative freedom meant little straightforward guidance, so my teammates and I self-taught everything we needed: Java EE web applications, the Stanford CoreNLP library, AJAX for parallel functionality runs, and Chrome Dev Tools performance benchmarking. When we tied together everything in a working application in the late hours of the night, it was a moment of euphoria. 🙌
      </p>
      <img src={commit} className="centering" draggable="false" alt="code commit victory"/>
      <p className="description">
      CodeU deepened my confidence and further affirmed why I love software engineering: the technical depth coupled with creative expression, fantastic collaborators, learning, and a community eager to give back. Thank you, Google!
      </p>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={group} height="200" width="300" draggable="false" alt="all codeU participants"/></a>
          <a className="carousel-item" href="#two!"><img src={screenshot} height="200" width="300" draggable="false" alt="screenshot"/></a>
          <a className="carousel-item" href="#three!"><img src={team} height="200" width="300" draggable="false" alt="Cornell team"/></a>
          <a className="carousel-item" href="#four!"><img src={aboutcodeu} height="200" width="300" draggable="false" alt="about the program"/></a>
          <a className="carousel-item" href="#five!"><img src={banner} height="200" width="300" draggable="false" alt="codeU banner"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }

  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={hooli} alt="Google CodeU" draggable="false"/>
              </div>
              <div className="gallery-header">
              <span style={{fontFamily: 'Montserrat, sans-serif', fontSize:'15px'}}><span style={{color:'#4285f4'}}>G</span><span style={{color:'#ea4335'}}>o</span><span style={{color:"#fbb305"}}>o</span><span style={{color:"#4285f4"}}>g</span><span style={{color:"#34a853"}}>l</span><span style={{color:"#ea4335"}}>e</span></span>
                <span> CodeU</span>
                <br/>
                <span className="subtitle-proj">Software Development</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Google CodeU</h3>
                  <span className="price">2016</span>
                </div>
                {this.state.projtext}
              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/jericahuang/Search-Engine" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>
              </div>
            </div>
          </div>

      );
  }
}
 export default GoogleCodeU;

import React, { Component } from "react";
import rhok from '../../img/proj-rhok.jpg';

import news from '../../img/rhok-win-msg.jpg';

import winners from '../../img/rhok-winners.jpeg';
import game from '../../img/rhok-game.jpg';
import working from '../../img/rhok-working.jpg';
import pitch from '../../img/rhok-pitch.jpg';
import testing from "../../img/rhok-testing.jpg";

class RHoK extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        Random Hacks of Kindness is a hackathon with a philanthropic focus. This interdisciplinary event brought together students from across degrees, majors, and schools at Cornell
        to solve problems proposed by community partners using technology.
      </p>
      <p className="description">
        One project proposal was put forth by Accenture and Women in Computing at Cornell (WICC): to build an app to drive the participation of girls to the new Girls Who Code club that would soon be launched at Cornell, hosted by WICC and Accenture. I knew I could draw upon my leadership role in WICC, my passion for the cause, my web and mobile development skill, and inspiration from attending my first ever Grace Hopper Celebration in Houston, Texas a month prior. The first night of the hackathon, I pitched my interest and background to others who were interested in this proposal, and assembled in a team of six CS majors.
      </p>
      <p className="description">
        During an initial brainstorming session, we had only thought of creating a solution that teaches coding skills to prospective students. But taking a step back, I asked, "Will a new educational app address the prompt of <i>motivating</i> girls to join Girls Who Code?" While learning methodologies are important, I thought that creating a Codecademy or Treehouse clone would not only be re-inventing the wheel, but also infeasible over the course of 36 hours. Reflecting on my journey, I shared the factors that inspire me to study Computer Science despite the lack of equal representation: a supportive community, the "epic win" of getting your code to work, the broad impact, and how applicable computing is to other fields. I encouraged my teammates to share their journeys too. By examining the common threads in our experiences, we ideated three major features for our application:
      </p>
      <p className="description">
        <b>Initial Excitement and Confidence</b>: Upon opening our app, you are greeted with a fun, quirky spy game where you evade the villain by typing in pseudocode-like JavaScript commands. This feature excites users and shows them that coding is as simple as typing familiar words!
        <br/>
        <b>Education</b>: Education: The page transitions into a resource bank with information about the Girls Who Code club, educational resources, news articles and interviews featuring women pioneering in computer science, and motivational videos.
        <br/>
        <b>Community</b>: The navigation bar links to a social forum. This is a free chat space with different groups. With each group, there is space to converse about project collaborations, share ideas, discover events, and meet fellow students and mentors. We implemented this with a Parse backend.

      </p>
      <p className="description">
        After creating a minimum viable prototype on Sunday morning, we conducted user testing with two girls belonging in our target demographic
        and incorporated their feedback. We delivered a live pitch to a full audience, and we were awarded first place among nearly 150 projects
        that weekend!<br/><br/>
        <img src={news} className="centering" width="40%" alt="1st place mention" draggable="false"/><br/>
        With support from our Accenture mentors and the momentum generated from that weekend, we refined and deployed our app to the Girls Who Code club at Cornell. This was pushed to a different endpoint with the link to the website easily shared in posters and by word of mouth in schools. In December 2015, we were invited to give a virtual presentation to six Girls Who Code executives about our project and its potentiality for integrating with other Girls Who Code clubs. They were especially interested in how the functionalities we developed support community building, with results already visible for the club at Cornell. They thanked us for our work, and would take ownership of seeing an idea such as ours implemented on a broader scale.
        <br/><br/>
        That soon became “<a href=
        "https://thejournal.com/articles/2016/10/12/girls-who-code-debuts-app.aspx" target="_blank" rel="noopener noreferrer">Loop</a>,” which was launched in October 2016.<br/>
      </p>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={winners} width="300" height="200" draggable="false" alt="group winning with sponsors"/></a>
          <a className="carousel-item" href="#two!"><img src={game} width="300" height="200" draggable="false" alt="game screen"/></a>
          <a className="carousel-item" href="#three!"><img src={working} width="300" height="200" draggable="false" alt="group working"/></a>
          <a className="carousel-item" href="#four!"><img src={pitch} width="300" height="200" draggable="false" alt="final pitch to judges and audience"/></a>
          <a className="carousel-item" href="#five!"><img src={testing} width="300" height="200" draggable="false" alt="testing with youth"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
        <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
            <div className="gallery-curve-wrapper">
              <div className="gallery-cover gray">
                <img className="responsive-img" src={rhok} alt="GWC" draggable="false"/>
              </div>
              <div className="gallery-header">
                <span>Random Hacks of Kindness <span role="img" aria-label="gold medal">🥇</span></span>
                <br/>
                <span className="subtitle-proj">Philanthropic Hackathon</span>
              </div>
              <div className="gallery-body">
                <div className="title-wrapper">
                  <h3>Random Hacks of Kindness</h3>
                  <span className="price">November 2015</span>
                </div>
                {this.state.projtext}
              </div>
              <div className="gallery-action">
                <a className="btn-floating btn-large waves-effect waves-light" href="https://codingconnection.azurewebsites.net" target="_blank" rel="noopener noreferrer"><i className="material-icons">desktop_mac</i></a>

                <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/agleis/randomhacks" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>

                <a className="btn-floating btn-large waves-effect waves-light" href="http://cornellsun.com/2015/11/17/students-tackle-community-issues-at-24-hour-hackathon/" target="_blank" rel="noopener noreferrer"><i className="material-icons">article</i></a>

              </div>
            </div>
          </div>

      );
  }
}
 export default RHoK;

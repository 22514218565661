import React, { Component } from "react";
import threeds from '../../img/3ds-team.jpg';

import all from "../../img/3ds-all.jpg";
import pitch from "../../img/3ds-pitch.jpg";
import vote from "../../img/3ds-vote.jpg";
import talk from "../../img/3ds-talk.jpg";
import work from "../../img/3ds-work.jpg";
import kebo from "../../img/3ds-kebo.png";

class Startup extends Component{
  constructor(props) {
    super(props);
    this.state = {clicked: false, projtext: ""};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.state.clicked === false){
      this.setState(state => ({
      projtext:
      <div>
      <p className="description">
        After my first hackathon experience in BigRed//Hacks 2014, I was hungry for more. A month later, the spirit of 3-Day Startup immediately captured my attention. I was eager to experience how to turn a weekend-long technical innovation into a viable business venture.
      </p>
      <p className="description">
        From the very first night, the energy at the venue was invigorating. 30 participants from all disciplines, who started out barely knowing one another, immediately took to discussing big ideas, complex issues, and personal passions. We were joined by mentors from technology, law, and business backgrounds, as well as past student participants. After a night of pitches, groups formed around an idea to turn into a startup reality in one weekend. My team’s idea was “Kebo”, a mobile app platform to bring visibility to local restaurants with a Tinder-like swipe discovery system for users who are undecided about what to eat. The name “Kebo” comes from the word “cibo,” which means “food” in Italian!
      </p>
      <img src={kebo} className="centering" alt="Kebo plate logo" width="200" draggable="false"/>
      <p className="description">
        The next two days were an adrenaline rush. Time ticked by with excitement, anticipation, and pride, as each moment of work and thought made our idea closer to becoming a reality. The uncertainty met with the thrill of discovery and belief in our idea motivated each team member to work to their fullest potential. Not only did we develop a fully functional demo app, but we also took to the streets of Ithaca to do customer discovery, idea validation, and business model generation.
      </p>
      <p className="description">
      I’ll never forget the early twilight hours before the final pitch day: we were short on sleep from the previous nights, but not tired in the slightest. The passion and conviction among our team radiated. Ideas were still bouncing through the roof on final edits to our pitch and app prototype. When it came time to pitch “Kebo” to investors and successful entrepreneurs, I was in awe that<br/><br/><i className="centering">we <b>made</b> that… over the weekend.</i><br/>We emerged as stronger individuals, and together, a stronger team. 3-Day Startup allowed each of us to hone our craft and learn by doing, and in the process, create something with the potential to change the world.
      </p>
      <p className="description">
      At both 3-Day Startup and BigRed//Hacks 2014, I had a powerful sense of awareness that this is what passion felt like, and I’ve found it. I’m so grateful to have discovered the brimming entrepreneurial culture at Cornell in these spaces, both in my first semester. The next few years, I served on the organizing and mentor team for the event. Each year and every startup creation just affirms that all it takes is the people, the energy, the environment, and 3 days for magic to happen.
      </p>
      <div className="carousel-wrapper">
        <div className="carousel">
          <a className="carousel-item" href="#one!"><img src={all} width="300" height="200" draggable="false" alt="group presentation"/></a>
          <a className="carousel-item" href="#two!"><img src={vote} width="300" height="200" draggable="false" alt="project voting"/></a>
          <a className="carousel-item" href="#three!"><img src={threeds} width="300" height="200" draggable="false" alt="team together"/></a>
          <a className="carousel-item" href="#four!"><img src={talk} width="300" height="200" draggable="false" alt="group conversation"/></a>
          <a className="carousel-item" href="#five!"><img src={work} width="300" height="200" draggable="false" alt="groups working"/></a>
        </div>
      </div>
      </div>,
      clicked:true
      }));
    }
  }


  render(){
    return (
      <div className="col l4 m6 s12 gallery-item gallery-expand gallery-filter personal" onClick={this.handleClick}>
        <div className="gallery-curve-wrapper">
          <div className="gallery-cover gray">
            <img className="responsive-img" src={pitch} alt="Kebo project" draggable="false"/>
          </div>
          <div className="gallery-header">
            <span>3-Day Startup Cornell</span>
            <br/>
            <span className="subtitle-proj">Entrepreneurship, Event Directing</span>
          </div>
          <div className="gallery-body">
            <div className="title-wrapper">
              <h3>3-Day Startup</h3>
              <span className="price">2014-2016</span>
            </div>
            {this.state.projtext}

          </div>
          <div className="gallery-action">
          <a className="btn-floating btn-large waves-effect waves-light" href="https://3dscornell.splashthat.com/" target="_blank" rel="noopener noreferrer"><i className="material-icons">article</i></a>

            <a className="btn-floating btn-large waves-effect waves-light" href="https://github.com/emilywhou/kebo" target="_blank" rel="noopener noreferrer"><i className="material-icons">code</i></a>
          </div>
        </div>
      </div>


      );
  }
}
 export default Startup;
